import React from 'react';
import { Carousel } from '@mantine/carousel';
import { useTheme } from '@emotion/react';
import { useScreenLayout } from 'common-ui';

interface CmsCarouselProps {
  children: React.ReactNode;
  slidesPerBlock: number;
  slidesToScroll: number;
  dataTestId?: string;
}

export const CmsCarousel = ({
  slidesPerBlock,
  slidesToScroll,
  children,
  dataTestId = 'cms-carousal',
}: CmsCarouselProps) => {
  const theme = useTheme();
  const slideSize = 100 / slidesPerBlock;
  const { isMobile } = useScreenLayout();
  return (
    <Carousel
      withIndicators
      loop
      slidesToScroll={isMobile ? 1 : slidesToScroll}
      slideSize={isMobile ? '100%' : `${slideSize}%`}
      align="start"
      slideGap="md"
      controlsOffset="md"
      styles={theme.widgets.CmsCarousel}
      data-testid={dataTestId}
    >
      {children}
    </Carousel>
  );
};

interface CmsCaruelSlideProps {
  children: React.ReactNode;
  dataTestId?: string;
}

export const CmsCarouselSlide = ({ children, dataTestId = 'cms-slide' }: CmsCaruelSlideProps) => {
  return <Carousel.Slide data-testid={dataTestId}>{children}</Carousel.Slide>;
};

CmsCarousel.CmsCarouselSlide = CmsCarouselSlide;
