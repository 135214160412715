import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import sanitizeHtml from 'sanitize-html';
import { ColourField } from 'tsconfig/types';
import { globals } from '../../../utils/globals';
import { inspectorMode } from 'common-ui';

export type CmsStripBannerProps = {
  cmsStripBanner?: string;
  cmsStripBannerBg?: ColourField;
  id?: string;
};

export const StripBanner = memo((props: CmsStripBannerProps) => {
  const { cmsStripBannerBg, cmsStripBanner } = props || {};
  const theme = useTheme();
  const widgetTheme = theme.widgets.GlobalHeader || {};
  const wrapper = css`
    ${widgetTheme.stripBanner}
    ${cmsStripBannerBg && `background-color: ${cmsStripBannerBg.value};`}
  `;

  if (!cmsStripBanner || globals.isEmbedded) {
    return null;
  }

  const contentToSanitize =
    typeof cmsStripBanner === 'string' && (cmsStripBanner as string).replace(/<[^>]*>/g, '').trim() && cmsStripBanner;
  const sanitizedHTML = sanitizeHtml(contentToSanitize as string, { allowedAttributes: false });

  return (
    <div data-test="widget-StripBanner" css={wrapper}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} {...inspectorMode(props.id, 'stripBanner')} />
    </div>
  );
});
