import { useState, useEffect, memo } from 'react';
import { CmsImage, globals, Icon, Rating, saleTag } from 'common-ui';
import Image from 'next/image';
import { CartButton } from '../AddToCart/Button';
import { css, useTheme } from '@emotion/react';
import { ColourField, ImageEntryType } from 'tsconfig/types';
import { formatCurrency } from '../../utils/formatCurrency';
import { productLinkBuilder } from 'common-next/src/components/common-functions';

export interface ProductCardProps {
  productName: string;
  productRating?: number;
  productReviewCount?: number;
  productPrice?: number;
  productImage?: string;
  productVintage?: string;
  productCardBackgroundImage?: ImageEntryType;
  productCardBackgroundColor?: ColourField;
  productCardButton?: string;
  productCode: string;
  productCardVariant?: string;
  productType?: string;
}

export const ProductCard = memo((props: ProductCardProps) => {
  const theme = useTheme();
  const {
    productName,
    productRating,
    productReviewCount,
    productPrice,
    productImage,
    productVintage,
    productCardBackgroundImage,
    productCardBackgroundColor,
    productCardVariant,
    productCardButton,
    productCode,
    productType,
  } = props || {};
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    setImageURL(productImage as string);
  }, [productImage]);

  const handleImageError = () => {
    setImageURL(
      `${globals.publicHost}${globals.defaultImagePath}/${productType === 'Case' ? 'default_case' : 'default_bottle'}.png`,
    );
  };

  const productCard = css`
    ${productCardVariant === 'Full bleed background'
      ? theme.widgets.Product?.productCardFullBleed
      : theme.widgets.Product?.productCardStandard}
  `;

  const isCase = productType === 'Case' ? true : false;

  return (
    <>
      <div css={productCard} className="CmsProductCard">
        <div className="product-card" data-testid="product-card">
          <div className="product-image-and-info">
            {productCardVariant === 'Full bleed background' ? (
              <div className="product-image-wrapper">
                <div
                  className="product-background-image"
                  style={{
                    backgroundColor: `${productCardBackgroundColor?.value}`,
                  }}
                >
                  {productCardBackgroundImage ? (
                    <CmsImage contentItem={productCardBackgroundImage} />
                  ) : (
                    <div className="product-image">
                      <Image
                        src={imageURL}
                        alt={productImage || 'Product Image'}
                        className={isCase ? 'product-case-default-image' : 'product-bottle-image'}
                        width="10"
                        height="90"
                        onError={handleImageError}
                        layout="responsive"
                        objectFit="cover"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <a
                className="product-image-wrapper"
                href={productLinkBuilder(productName, productVintage, productCode)}
                style={{ backgroundColor: `${productCardBackgroundColor?.value}` }}
              >
                <div className="product-image">
                  <Image
                    src={imageURL}
                    alt={productName || 'Product Image'}
                    className={isCase ? 'product-case-default-image' : 'product-bottle-image'}
                    width="10"
                    height="10"
                    onError={handleImageError}
                    objectFit="cover"
                  />
                </div>
              </a>
            )}
            <div className="product-card-info">
              {saleTag(productVintage) && (
                <>
                  <div className="product-sale-status">
                    <Icon kind="sale-tag-no-text" size="m" />
                    <p className="product-on-sale-text">On Sale</p>
                  </div>
                </>
              )}
              <div className="product-info-and-button">
                <div>
                  <div className="product-name-box">
                    <a href={productLinkBuilder(productName, productVintage, productCode)}>
                      <h3 className="product-name">
                        {productName} {productVintage}
                      </h3>
                    </a>
                  </div>
                  {(productRating || productReviewCount) && (
                    <div className="product-rating">
                      {productRating && <Rating rating={productRating} />}
                      {productReviewCount && <span>({productReviewCount})</span>}
                    </div>
                  )}
                  {productPrice && <div className="product-price">{formatCurrency(productPrice)} each</div>}
                </div>
                {productCardButton && (
                  <div className="product-card-button">
                    {productCardButton.toLocaleLowerCase() === 'Add to Basket'.toLocaleLowerCase() ? (
                      <CartButton itemCode={productCode} qty={'1'} buttonText={productCardButton} />
                    ) : (
                      <a
                        href={productLinkBuilder(productName, productVintage, productCode)}
                        className="view-product-button"
                      >
                        {productCardButton}
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
