import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import {
  Button,
  BottleCount,
  Icon,
  NEW_CART_PAGE,
  OLD_CART_PAGE,
  STATIC_WINE_PATHNAME,
  Loading,
  globals,
} from 'common-ui';
import Image from 'next/image';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { formatCurrency } from 'common-ui/src/utils/formatCurrency';
import { useCheckout } from 'common-next/src/pages/CartPage/useCheckout';
import { MiniCartLayoutProps } from 'tsconfig/types';

export const giftCardimage = (brandId: string) => {
  if (brandId === 'law') {
    brandId = 'lw';
  }
  return `${globals.publicHost}/images/us/en/product/${brandId}-giftcard.png`;
};
const MiniCartLayout: React.FC<MiniCartLayoutProps> = ({
  lineItems,
  totalLineItem,
  onClose,
  isFreeGiftLineItem,
  isGreatPick,
  qty,
  showCheckoutButton,
}) => {
  const redirectCheckoutRoute = useCheckout();
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonClick, setButtonClick] = useState<string>('VIEW_BASKET');

  const defaultImageSrc = `${globals.publicHost}${globals.defaultImagePath}/${
    lineItems[0]?.product?.productType === 'Case' ? 'default_case' : 'default_bottle'
  }.png`;

  const [imageSrc, setImageSrc] = useState<string>(defaultImageSrc);

  useEffect(() => {
    // Update imageSrc when lineItems change
    // TODO: Need to update this once AWS cart API is implemented for US
    const newImageSrc =
      globals.country === 'us'
        ? lineItems[0]?.product?.productType.toLowerCase() === 'voucher'
          ? giftCardimage((globals?.brandId as string) ?? '')
          : `${globals.publicHost}/${lineItems[0]?.product?.thumbnailImage}`
        : lineItems[0]?.product?.smallImage || '';
    setImageSrc(newImageSrc);
  }, [lineItems]);

  const handleRedirectionClick = (buttonType: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    setButtonClick(buttonType);
    setLoading(true);
    setTimeout(() => {
      onClose();
      setLoading(false);
    }, 400);
  };

  const handleImageError = () => {
    setImageSrc(defaultImageSrc);
  };

  const redirectLink = globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE;

  const VIEW_CART_TEXT = globals?.country === 'us' ? 'cart' : 'basket';
  const message = `You ${globals.country === 'us' ? 'qualify for' : 'got'} a free gift`;

  const renderGift = (className: string) => {
    return (
      <div className={className}>
        <Icon kind="gift" size="20px" />
        <h4>
          <span>{message}</span>
          {globals.useNewCartAPI ? (
            <Link href={redirectLink}>
              <span
                className="cart-link light-grey"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick('VIEW_BASKET', e)}
              >
                {`View in ${VIEW_CART_TEXT}`}
              </span>
            </Link>
          ) : (
            <a href={redirectLink}>
              <span
                className="cart-link light-grey"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick('VIEW_BASKET', e)}
              >
                {`View in ${VIEW_CART_TEXT}`}
              </span>
            </a>
          )}
        </h4>
      </div>
    );
  };

  const ViewCart = () => {
    return (
      <Button
        kind="secondary"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick('VIEW_BASKET', event)}
        disabled={loading && buttonClick === 'VIEW_BASKET'}
        className="view-basket"
      >
        {loading && buttonClick === 'VIEW_BASKET' ? (
          <span>
            <Loading />
          </span>
        ) : (
          <span>
            VIEW {VIEW_CART_TEXT.toUpperCase()} ({totalLineItem} ITEM{totalLineItem > 1 && 'S'})
          </span>
        )}
      </Button>
    );
  };
  return (
    <>
      <div className="wrapper">
        <div className="product-image">
          <Image
            src={imageSrc}
            alt={lineItems[0]?.sku?.productName[0]?.value || ''}
            sizes="100vw"
            width={216}
            height={216}
            unoptimized
            onError={handleImageError}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="product-details-container">
          <p className="product-great-pick">{isGreatPick ? 'Another Great Pick!' : 'Great Pick!'}</p>
          <div className="product-detail-text">
            {lineItems[0]?.product?.productType?.toLowerCase() !== 'service' ? (
              <Link
                href={productLinkBuilder(
                  lineItems[0]?.sku?.productName[0]?.value,
                  '',
                  lineItems[0]?.sku?.itemCode,
                  false,
                )}
                className="product-link"
              >
                <span className="checkout-item-qty">{globals.country === 'us' ? lineItems[0]?.quantity : qty}× </span>
                {lineItems[0]?.sku?.productName[0]?.value}
              </Link>
            ) : (
              <div className="product-link">{lineItems[0]?.sku?.productName[0]?.value}</div>
            )}
          </div>
          <BottleCount
            productType={
              lineItems[0]?.product?.skus?.[0]?.referencedSkus?.[0]?.expandedItem?.productType
                ? lineItems[0]?.product?.skus?.[0]?.referencedSkus?.[0]?.expandedItem?.productType
                : lineItems[0]?.product?.productType
            }
            bottleType={lineItems[0]?.product?.bottleType}
            numberOfBottles={lineItems[0]?.sku?.numberOfBottles}
          />
          <div className="price-section">
            {globals.country === 'us' ? (
              <>
                {lineItems[0]?.savings?.totalProductDiscountSavings > 0 && (
                  <p className="price strike">
                    {formatCurrency(lineItems[0]?.unitPrice?.listPrice?.amount * lineItems[0]?.quantity)}
                  </p>
                )}
                <p
                  className={`price sale-price ${lineItems[0]?.unitPrice?.priceType === 'MemberPrice Discount' ? 'member-price' : ''}`}
                >
                  {formatCurrency(lineItems[0]?.unitPrice?.value?.amount)}
                </p>
              </>
            ) : (
              <p className="price">{formatCurrency(lineItems[0]?.unitPrice?.value?.amount * Number(qty))}</p>
            )}
          </div>
          {globals.country === 'us' && lineItems[0]?.unitPrice?.priceType === 'MemberPrice Discount' && (
            <p className="member-savings">
              {formatCurrency(lineItems[0]?.savings?.totalProductDiscountSavings)}{' '}
              {globals.brandId === 'wsj' ? 'ADVANTAGE' : 'UNLIMITED'} MEMBER SAVINGS
            </p>
          )}
          {isFreeGiftLineItem && renderGift('gift-tablet')}
        </div>
      </div>

      {isFreeGiftLineItem && renderGift('gift-other-device')}

      <div className="button-container">
        {globals.useNewCartAPI ? (
          <Link href={redirectLink}>
            <ViewCart />
          </Link>
        ) : (
          <a href={redirectLink}>
            <ViewCart />
          </a>
        )}
        {showCheckoutButton && (
          <a href={redirectCheckoutRoute}>
            <Button
              kind="primary"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick('CHECKOUT', event)}
              disabled={loading && buttonClick === 'CHECKOUT'}
            >
              {loading && buttonClick === 'CHECKOUT' ? (
                <span>
                  <Loading />
                </span>
              ) : (
                <span>CHECKOUT</span>
              )}
            </Button>
          </a>
        )}
      </div>

      <div className="continue-shopping mobile">
        <a href={STATIC_WINE_PATHNAME} onClick={e => handleRedirectionClick('CONTINUE_SHOPPING', e)}>
          continue shopping
        </a>
      </div>
    </>
  );
};

export default MiniCartLayout;
