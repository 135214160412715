import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { TextEntryType, TextVariant } from 'tsconfig/types.d';

export type CmsTextProps = {
  contentItem: TextEntryType;
};

function getTagForVariant(variant: TextVariant) {
  switch (variant) {
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
      return variant;

    case 'seperator':
      return 'hr';

    case 'blank':
    case 'body':
    default:
      return 'div';
  }
}

export const CmsText = memo((props: CmsTextProps) => {
  const { title, variant } = props.contentItem.fields;
  const theme = useTheme();
  const wrapper = css`
    &.blank {
      margin-bottom: ${theme.space.lg};
      padding-bottom: ${theme.space.lg};
    }
  `;
  const CustomTag = getTagForVariant(variant) as keyof JSX.IntrinsicElements;
  const text = variant === 'blank' ? '' : title;

  return (
    <CustomTag data-test="widget-CmsText" css={wrapper} className={variant === 'blank' ? 'blank' : ''}>
      {text}
    </CustomTag>
  );
});
