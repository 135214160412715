import React, { useState, useEffect, memo } from 'react';
import { ProductCardEntryType, ProductProps } from 'tsconfig/types';
import { ProductCard } from '../../ProductCard/ProductCard';
import useProductData from '../../../hooks/useProductData';
export interface CmsProductCardProps {
  contentItem: ProductCardEntryType;
}

export const CmsProductCard = memo(({ contentItem }: CmsProductCardProps) => {
  const { productCode, productCardBackgroundImage, productCardButton, productCardVariant, productCardBackgroundColor } =
    contentItem.fields || {};
  const { product: productData } = useProductData(productCode);

  return (
    <ProductCard
      productName={productData?.name || ''}
      productRating={productData?.averageOverallRating}
      productReviewCount={productData?.totalReviewCount}
      productPrice={
        productData?.skus?.[0]?.vppPrice !== 0 ? productData?.skus?.[0]?.vppPrice : productData?.skus?.[0]?.salePrice
      }
      productImage={productData?.smallImage || productData?.largeImage}
      productVintage={productData?.vintage || ''}
      productCardBackgroundImage={productCardBackgroundImage}
      productCardBackgroundColor={productCardBackgroundColor}
      productCardButton={productCardButton}
      productCode={productCode}
      productCardVariant={productCardVariant}
      productType={productData?.mixed ? 'Case' : productData?.productType ?? ''}
    />
  );
});
