import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { ColourField, DLClickEventHandlerProps, IconEntryType, ImageEntryType, LinkEntryType } from 'tsconfig/types.d';
import { Icon } from '../../Icon/Icon';
import { CmsImage } from '../CmsImage/CmsImage';
import { logout } from 'common-state';
import { dwChat } from '../../../dw-chat/DwChat';
import { inspectorMode } from 'common-ui';
import { Link } from '../../Link/Link';

export type CmsLinkProps = {
  contentItem: LinkEntryType;
  category?: string;
  className?: string;
  dlClickEventHandler?: DLClickEventHandlerProps;
};
const UK_COOKIE_TARGET = 'optanon-toggle-display';

export const CmsLink = memo(({ contentItem, category, className, dlClickEventHandler }: CmsLinkProps) => {
  const theme = useTheme();
  const categoryType = category ? category : 'link';
  const { title, entryTitle, url, targeting, icon, variant } = contentItem?.fields || {};

  if (!url) {
    console.warn('No url value set for', contentItem?.fields);
  }

  const wrapper = css`
    display: block;
  `;
  const getTargeting = (key?: string[]) => {
    switch (true) {
      case key?.includes('cookie_setting'):
        return UK_COOKIE_TARGET;
      default:
        return '';
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>, targeting?: string[]) => {
    if (targeting?.includes('cookie_setting_osano')) {
      window?.Osano?.cm?.showDrawer?.('osano-cm-dom-info-dialog-open');
      event.preventDefault();
    }
    if (targeting?.includes('cookie_setting')) {
      event.preventDefault();
    }
    if (targeting?.includes('soft_logged')) {
      logout('hard_logged');
    }
    if (targeting?.includes('live_chat')) {
      event.preventDefault();
      dwChat.open();
    }
  };

  const renderIconImage = (iconImageField: LinkEntryType['fields']['icon']) => {
    if (!iconImageField) {
      return <></>;
    }
    let icon;
    if ((iconImageField as ImageEntryType)?.fields?.largeDeviceImage) {
      icon = iconImageField as ImageEntryType;
      return <CmsImage contentItem={icon} />;
    } else {
      icon = iconImageField as IconEntryType;
      const iconStyle = css`
        color: ${(icon?.fields?.colour as unknown as ColourField)?.value};
      `;
      return (
        <div css={iconStyle}>
          <Icon kind={icon.fields.iconType} size="m" />
        </div>
      );
    }
  };

  let variantType;
  if (variant === 'Secondary button') {
    variantType = css`
      && {
        ${theme.mixins.btnSecondary}
      }
    `;
  } else if (variant === 'Primary button') {
    variantType = css`
      && {
        ${theme.mixins.btnPrimary}
      }
    `;
  } else if (variant === 'Secondary-inverted button') {
    variantType = css`
      && {
        ${theme.mixins.btnTertiary}
      }
    `;
  } else {
    variantType = '';
  }

  return (
    <Link
      data-testid="widget-CmsLink"
      css={css([wrapper, variantType])}
      href={(url as string) || ''}
      data-event={`${categoryType}Click`}
      data-section={`${categoryType}:${title || entryTitle}`}
      data-action="navigation"
      data-name={`${categoryType} navigation`}
      data-text={title || entryTitle}
      data-nav-label={title || entryTitle}
      className={`${className ? className : ''} ${getTargeting(targeting)}`}
      onClick={(e: any) => {
        onClickHandler(e, targeting);
        dlClickEventHandler && dlClickEventHandler(title);
      }}
      {...inspectorMode(contentItem?.sys?.id, 'title' || 'entryTitle')}
    >
      {icon && renderIconImage(icon)}
      {title ? title : entryTitle}
    </Link>
  );
});
