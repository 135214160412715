// These are BFF endpoints that we expose from the FE.
// Note: We've misused the word 'BFF' in other places. This represents the actual FE BFF
// e.g. where we aggregate BE APIs for our specific use-cases
import axios from 'axios';
import { ProductProps } from 'tsconfig/types';

type BffApiConfig = {
  urlPrefix?: string;
  extraHeaders?: Record<string, string>;
};

export const initBffApi = (config: BffApiConfig) => {
  async function asyncRequest(requestType: string, url: string, data = {}) {
    const requestConfig: any = {
      method: requestType,
      url: (config.urlPrefix || '') + url,
      headers: {
        ...config.extraHeaders,
      },
    };
    if (data) {
      requestConfig.data = data;
    }
    const response = await axios.request(requestConfig);
    return response.data;
  }

  return {
    Product: {
      getAllProductData: async (itemCodeArray: string): Promise<ProductProps[]> =>
        await asyncRequest('GET', `/bff/frontend/product?itemCodes=${itemCodeArray}`),
      compliance: async (stateCode: string, itemCode: string) =>
        await asyncRequest('GET', `/bff/frontend/productCompliance?stateCode=${stateCode}&itemCode=${itemCode}`),
    },
  };
};
