import { css, useTheme } from '@emotion/react';
import Link from 'next/link';
import React, { memo } from 'react';
import {
  SiteEntryType,
  NavigationEntryType,
  ImageEntryType,
  LinkEntryType,
  GuideLinePolicyEntryType,
} from 'tsconfig/types';
import ContainerLayout from '../../layouts/ContainerLayout';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import Grid from '../grid/Grid';
import { Icon } from '../Icon/Icon';
import NavItem from './NavItem';
import { CmsImage } from '../contentful/CmsImage/CmsImage';
import Image from 'next/image';
import { inspectorMode } from 'common-ui';

export type GlobalFooterProps = {
  cmsSiteConfig?: SiteEntryType;
};

export const GlobalFooter = memo((props: GlobalFooterProps) => {
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Footer?.default}
  `;
  const {
    footerNavBar,
    copyright,
    guidelinePolicy,
    badgeImages,
    socialLinks = [],
  } = props?.cmsSiteConfig?.fields || {};

  return (
    <div css={wrapper} data-test="footer" className="no-print">
      <ContainerLayout>
        {footerNavBar && (
          <section className="footer-nav-links" aria-label="Footer links" data-test="links-section">
            <Grid className="wrapper" justify={'between'}>
              {(footerNavBar as NavigationEntryType[]).map(({ fields }, index: number) => {
                return <NavItem key={index} fields={fields} />;
              })}
            </Grid>
            {/* <!--BE IXF: For your website, the location is   --> */}
            <div className="be-ix-link-block"></div> {/*<!--Link Block Target Div-->*/}
            {/* <!--BE IXF: end --> */}
          </section>
        )}
        {guidelinePolicy && (
          <section className="guidelines-policies" data-test="guidelines-section">
            <Grid className="wrapper" align={'center'} justify="around">
              {(guidelinePolicy as GuideLinePolicyEntryType[]).map(({ fields, sys }, index: number) => (
                <Grid.Col key={index}>
                  {fields.image && (
                    <Image
                      src={fields.image.fields.file?.url || ''}
                      alt={fields.image.fields.description || ''}
                      width={fields.image.fields.file?.details.image?.width}
                      height={fields.image.fields.file?.details.image?.height}
                      {...inspectorMode(sys.id, 'image')}
                    />
                  )}
                  <div {...inspectorMode(sys.id, 'title')}>
                    <p className="title">{fields.title}</p>
                    <p className="desc">{fields.description}</p>
                  </div>
                </Grid.Col>
              ))}
            </Grid>
          </section>
        )}
        {socialLinks?.length > 0 && (
          <section className="social-connections" data-test="social-section">
            <Grid className="wrapper" align={'center'} justify={'center'} noGutter>
              {socialLinks?.length < 3 && (
                <Grid.Col xs="auto" className="social-connection-heading">
                  <p>Follow Us</p>
                </Grid.Col>
              )}
              {(socialLinks as LinkEntryType[]).map(({ fields }, index: number) => (
                <Grid.Col xs="auto" key={index}>
                  <Link prefetch={false} href={fields.url ?? ''}>
                    <Icon kind={fields.title} size="l" data-test={fields.title} />
                  </Link>
                </Grid.Col>
              ))}
            </Grid>
          </section>
        )}
        <section className="footer-bottom-section" data-test="bottom-section">
          <Grid className="wrapper" align={'center'} justify={'between'} noGutter>
            <Grid.Col lg={4} className="copyrights">
              <div {...inspectorMode(props.cmsSiteConfig?.sys.id, 'copyright')}>
                {documentToReactComponents(copyright as Document)}
              </div>
            </Grid.Col>
            <Grid.Col lg={4} className="brand">
              <a href="/" className="footer-logo">
                <picture>
                  <Image
                    src={theme.assets.footerLogoImgUrl || ''}
                    alt="Brand Logo"
                    sizes="(min-width: 1230px) 10em, 13.5em"
                    width={180}
                    height={40}
                    layout="responsive"
                    objectFit="cover"
                    className="brand-footer-img"
                  />
                </picture>
              </a>
            </Grid.Col>
            <Grid.Col lg={4} className="bedrinkaware-group">
              {(badgeImages as LinkEntryType[])?.map(({ fields }, index: number) => {
                const iconImage = fields.icon && (
                  <CmsImage
                    contentItem={fields.icon as ImageEntryType}
                    disableAutoSize
                    key={index}
                    isNextImg={true}
                    width={120}
                    height={120}
                    sizes="120px"
                  />
                );
                if (fields.url) {
                  return (
                    <div key={index}>
                      <Link prefetch={false} href={fields.url ?? ''}>
                        {iconImage}
                      </Link>
                    </div>
                  );
                }
                return iconImage;
              })}
            </Grid.Col>
          </Grid>
        </section>
      </ContainerLayout>
    </div>
  );
});
