import React, { useCallback, useMemo } from 'react';
import Image from 'next/image';
import { Rating } from 'common-ui/src/components/Rating/Rating';
import { css, useTheme, Theme } from '@emotion/react'; // Import the Theme type
import { Icon, Loading, getBottleLabel, globals, useDefaultImageHandling } from 'common-ui';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { cartAtom, useActionAddToCart, useRecoilValue } from 'common-state';
import { formatCurrency } from 'common-ui/src/utils/formatCurrency';
import { TrendingItemProps } from 'tsconfig/types';

const productItemStyles = (theme: Theme) => css`
  ${theme.widgets.Product?.productItem}
`;

const productItemTrendingStyles = (theme: Theme) => css`
  ${theme.widgets.Cart?.productItemsTrending}
`;

const separateText = (text: string): [string, string] => {
  const separator = ' - ';
  const [leftString, rightString] = text.split(separator);
  return [leftString, rightString];
};

const OverlayTextComponent: React.FC<{ text: string }> = ({ text }) => {
  const [left, right] = separateText(text);
  return (
    <p className="overlay-text">
      <strong>{left}</strong>
      {right && ` - ${right}`}
    </p>
  );
};

export const TrendingItem: React.FC<TrendingItemProps> = ({
  item,
  handleError,
  setLoading,
  isMiniCart,
  setIsGreatPick,
}) => {
  const theme = useTheme();
  const addToBasket = useActionAddToCart();
  const isCartDataLoaded = useRecoilValue(cartAtom);

  const { imageSrc, handleImageError } = useDefaultImageHandling(
    `${globals.publicHost}/images/uk/en/law/product/${item.itemCode}.png`,
    `${globals.publicHost}${globals.defaultImagePath}/default_bottle.png`,
  );

  const productData = useMemo(
    () => ({
      name: item.productName || item.name,
      vintage: item.vintage || '',
      itemCode: item.itemCode,
      giftFlag: item.giftFlag || false,
      avgRating: item.avgRating || item.rating || 0,
      skus: item.skus,
      numberOfReviews: item.numberOfReviews || 0,
      country: item.country || '',
      style: item.style || '',
      grape: item.grape || '',
      productType: item?.productType || '',
    }),
    [item],
  );

  const handleAddToBasketClick = useCallback(async () => {
    setLoading(true);
    try {
      await addToBasket(item.skus[0].itemCode, '1');
      if (isMiniCart) {
        setIsGreatPick && setIsGreatPick(true);
      }
    } catch (error: any) {
      const msg = error?.response?.data?.message || 'An error occurred while adding to cart';
      handleError(true, msg);
    } finally {
      setLoading(false);
    }
  }, [addToBasket, handleError, isMiniCart, item.skus, setIsGreatPick, setLoading]);

  return (
    <div css={[productItemStyles(theme), productItemTrendingStyles(theme)]} className="product-item-container">
      <div className="product-image">
        <a href={productLinkBuilder(productData.name, productData.vintage, productData.itemCode, productData.giftFlag)}>
          <Image
            src={imageSrc}
            alt={productData?.name || 'product image'}
            fill
            sizes="100vw"
            unoptimized
            onError={handleImageError}
            style={{ objectFit: 'contain' }}
          />
        </a>
        {isMiniCart && (productData?.style !== '' || productData?.grape !== '' || productData?.country !== '') && (
          <div className="info-overlay">
            {productData?.style && (
              <div className="overlay-info-container">
                <Icon kind="glass" size={'20px'} />
                <OverlayTextComponent
                  text={productData?.style !== '' ? productData?.style : 'Red - Medium/Full Bodied'}
                />
              </div>
            )}
            {productData?.grape && (
              <div className="overlay-info-container">
                <Icon kind="grape" size="20px" />
                <p className="overlay-text">{productData?.grape !== '' ? productData?.grape : 'Touriga Nacional'}</p>
              </div>
            )}
            {productData?.country && (
              <div className="overlay-info-container">
                <Icon kind="location" size="20px" />
                <OverlayTextComponent text={productData?.country} />
              </div>
            )}
          </div>
        )}
      </div>
      <a
        className="product-title"
        href={productLinkBuilder(productData.name, productData.vintage, productData.itemCode, productData.giftFlag)}
      >
        {productData.name} {productData.vintage}
      </a>
      <div className="product-rating">
        <Rating rating={productData.avgRating} />
        <span className="review-count">({productData.numberOfReviews})</span>
      </div>
      <div className="product-price">
        {productData.skus[0]?.lowestPricePerBottle && (
          <span className="price-cart">
            {`From ${formatCurrency(productData.skus[0].lowestPricePerBottle)} a ${getBottleLabel(productData?.productType || '').toLocaleLowerCase()}`}
          </span>
        )}
      </div>
      <div className="product-detail-cart" onClick={() => isCartDataLoaded && handleAddToBasketClick()}>
        {isCartDataLoaded ? 'Add to basket' : 'Loading'}
      </div>
    </div>
  );
};
