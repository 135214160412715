import aa from 'search-insights';
import { globals } from './globals';
export const handleAlgoliaClickEvent = (queryID: string, eventName: string, sendEvent: any, hit: any) => {
  // Set the queryID in localStorage
  localStorage.setItem('queryID', queryID);

  // Call the sendEvent function
  sendEvent('click', hit, eventName);
};

export const handleAlgoliaAddToCartEvent = (queryID: string, eventName: string, itemCode: string) => {
  aa('convertedObjectIDsAfterSearch', {
    eventName,
    index: `${globals.algoliaIndexName}`,
    queryID,
    objectIDs: [itemCode],
  });
};
