import React from 'react';

const PlayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="113px" height="113px" viewBox="0 0 113px 113px" fill="none">
      <circle cx="56.5" cy="56.5" r="56.5" fill="black" fillOpacity="0.8" />
      <circle cx="56.4993" cy="56.4993" r="36.9133" fill="#CF004F" fillOpacity="0.7" />
      <path
        d="M75.1772 54.8378C76.4544 55.5752 76.4544 57.4185 75.1772 58.1559L48.597 73.502C47.3199 74.2394 45.7235 73.3177 45.7235 71.843L45.7235 41.1507C45.7235 39.676 47.3199 38.7544 48.597 39.4917L75.1772 54.8378Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(PlayIcon);
