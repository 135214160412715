import { BPKey, Theme } from '@emotion/react';
import { formQueryString, getMarginValue, getToPxValue, getValues } from '../../utils/grid.utils';
import { commonThemeVars } from './themeCommonVars';

const animationSpins = {
  linearInfinite: 'animation: spin 1s linear infinite;',
};

const gridBase = `
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-width: 0;
  margin-right: -${commonThemeVars.grid.gutter.xs / 2}px;
  margin-left: -${commonThemeVars.grid.gutter.xs / 2}px;
`;

const gridNoGutter = `
    > div {
      padding-left: 0;
      padding-right: 0;
    }
`;

const colBase = `
  max-width: 100%;
  box-sizing: border-box;
  padding-bottom: 10.5px;
  padding-top: 10.5px;
  padding-right: ${commonThemeVars.grid.gutter.xs / 2}px;
  padding-left: ${commonThemeVars.grid.gutter.xs / 2}px;
`;

export const commonThemeMixins = (commonVars: Omit<Theme, 'mixins' | 'widgets'>) => {
  return {
    containerLayout: `
      max-width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 16px;
    `,
    containerLayoutFixed: `
    ${formQueryString(
      commonVars.breakpoints,
      (bp: BPKey) => `
        max-width: ${getToPxValue<string>(commonVars.grid, 'container', bp, '')};
        margin-left: ${getMarginValue<string>(commonVars.grid, 'margin', bp, 'auto')};
        margin-right: ${getMarginValue<string>(commonVars.grid, 'margin', bp, 'auto')};
        padding: 0;
      `,
    )}
    `,
    visuallyHidden: `
      position: absolute!important;
      width: 1px!important;
      height: 1px!important;
      padding: 0!important;
      margin: -1px!important;
      overflow: hidden!important;
      clip: rect(0,0,0,0)!important;
      white-space: nowrap!important;
      border: 0!important;  
    `,
    animateFadeInDown: `
      animation: fadeDown 0.5s;
    `,
    animateFadeOutUp: `
      animation: fadeOut 0.5s;
    `,
    animateSpin: `
      ${animationSpins.linearInfinite}
    `,
    spinnerBorder: `
      vertical-align: -0.125em;
      border: 0.25em solid;
      border-right-color: transparent;
    `,
    roundedFull: `
      border-radius: 9999px;
    `,
    loading: `
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid;
      border-color: #969696 #969696 #969696 transparent;
      ${animationSpins.linearInfinite}
    `,
    grid: `
      ${gridBase}
      ${formQueryString(
        commonVars.breakpoints,
        (bp: BPKey) => `
        margin-left: -${getValues<number>(commonVars.grid, 'gutter', bp) / 2}px;
        margin-right: -${getValues<number>(commonVars.grid, 'gutter', bp) / 2}px;
      `,
      )}
    `,
    col: `
     ${colBase}
     ${formQueryString(
       commonVars.breakpoints,
       (bp: BPKey) => `
        padding-left: ${getValues<number>(commonVars.grid, 'gutter', bp) / 2}px;
        padding-right: ${getValues<number>(commonVars.grid, 'gutter', bp) / 2}px;
      `,
     )}
    `,
    noGutter: `
      ${gridNoGutter}
    `,
    iconBase: `
      font-family: icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; 
    `,
    footerBase: `
      .footer-nav-links {
        background: ${commonVars.colors.white};
        box-shadow: 0 0 0 100vmax ${commonVars.colors.white};
        clip-path: inset(0 -100vmax);
        .wrapper {
          margin-left: 0px;
          margin-right: 0px;
          flex-direction: column;
          .tab-indicator {
            display: block;
          }
          ${commonVars.breakpoints.lg} {
            flex-direction: row;
            padding: 30px 0px 20px;
            .tab-indicator {
              display: none;
            }
          }
        }
      }
      .guidelines-policies {
        .wrapper {
          margin-top: 32px;
          margin-bottom: 32px;
          align-items: baseline;
          > * {
            margin-top: 21px;
            flex-basis: 50%;
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            gap: 14px;
          }
          p {
            margin: 0;
            line-height: 20px;
          }
          .title {
            text-transform: uppercase;
            margin: 7px 0px 9px;
          }
          ${commonVars.breakpoints.lg} {
            > * {
              margin-top: 0;
              flex-basis: auto;
            }
          }
        }
      }
      .social-connections {
        border-bottom: ${commonVars.colors.grey20} solid 0.5px;
        border-top: ${commonVars.colors.grey20} solid 0.5px;
        .wrapper {
          padding-top: 1em;
          padding-bottom: 1em;
          > div {
            margin: auto 1.3em;
            ${commonVars.breakpoints.md} {
              margin: auto 3em;
            }
          }
          .social-connection-heading {
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 20px;
            ${commonVars.breakpoints.md} {
              flex-basis: unset;
              text-align: unset;
              margin-bottom: auto;
            }
            p {
              margin: 0;
              text-transform: uppercase;
              font-weight: ${commonVars.fonts.weightBold};
              font-family: ${commonVars.fonts.familyPrimary};
              font-size: 18px;
              color: ${commonVars.colors.black};
            }
          }
        }
      }
      .footer-bottom-section {
        color: ${commonVars.colors.black};
        .wrapper {
          padding: 12px 0px;
          margin: 0;
          text-align: left;
          flex-direction: column-reverse;
          .brand {
            text-align: center;
            img {
              max-width: 10em;
              width: 100%;
              &.brand-footer-img {
                width: 180px !important;
              }
              ${commonVars.breakpoints.lg} { 
                max-width: 13.5em;
              }
            }
          }
          .bedrinkaware-group {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: calc(max(16px, 1vw)); // set column gap to min 16px and max can go till 1% of viewport width which can be 26px 
            flex-wrap: wrap;
            flex-direction: row-reverse;
            > *:first-of-type {
              flex-basis: 100%;
              text-align: center;
              margin-bottom: 1em;
            }
            ${commonVars.breakpoints.md} {
              > *:first-of-type {
                margin-bottom: 0em;
                flex-basis: unset;
                text-align: unset;
                
              }
            }
          }
          .copyrights {
            text-align: center; 
            ${commonVars.breakpoints.lg} {
              text-align: left;
            }
            *{
              font-size: ${commonVars.fonts.sizeSmall};
              font-family: ${commonVars.fonts.familySecondary};
              line-height: 1.3em;
              min-height: 1.3em;
            }
            p {
              margin: 0 0 5px 0;
            }
          }
          ${commonVars.breakpoints.lg} {
            flex-direction: row;
            .bedrinkaware-group {
              flex-wrap: nowrap;
            }
          }
        }
      }
    `,
    footerNavItems: `
      margin: 12px 0;
      > div:first-of-type {
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
      }
      .tab-indicator.tab-expand {
        transform: rotate(-180deg);
      }
      .tab-indicator.tab-collapse {
        transform: rotate(0deg);
      }
      .tab-panel.tab-expand {
        display: block;
      }
      .tab-panel.tab-collapse {
        display: none;
      }
      ${commonVars.breakpoints.lg} {
        .tab-panel.tab-collapse {
          display: block;
        }
      }
      @media (prefers-reduced-motion: no-preference) {
        .tab-indicator.tab-expand {
          transition-timing-function: ease-in;
          transition: 0.2s;
          transform: rotate(-180deg);
        }
        .tab-indicator.tab-collapse {
          transition-timing-function: ease-out;
          transition: 0.2s;
          transform: rotate(0deg);
        }
      }
      p {
        margin: 0;
        text-transform: uppercase;
      }
      ul {
        padding: 0;
        list-style: none;
        margin: 12px 0 0 20px;
        line-height: 35px;
        ${commonVars.breakpoints.lg} {
          margin-left: 0;
        }
        li {
          a div {
            display: inline-block;       
            margin-right: 17px;
          }
        }
      }
    `,
    hxl: `
      font-family: ${commonVars.fonts.familyPrimary};
      font-size: ${commonVars.fonts.fontSize45};
      font-weight: ${commonVars.fonts.weightMedium};
      line-height: ${commonVars.fonts.fontLineHeight100};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing1};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize64};
      }
    `,
    h1: `
      font-family: ${commonVars.fonts.familyPrimary};
      font-size: ${commonVars.fonts.fontSize34};
      font-weight: ${commonVars.fonts.weightMedium};
      line-height: ${commonVars.fonts.fontLineHeight105};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing1};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize48};
        letter-spacing: ${commonVars.fonts.fontLetterSpacing2};
      }
    `,
    h2: `
      font-family: ${commonVars.fonts.familyPrimary};
      font-size: ${commonVars.fonts.fontSize30};
      font-weight: ${commonVars.fonts.weightMedium};
      line-height: ${commonVars.fonts.fontLineHeight105};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing1};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize34};
        letter-spacing: ${commonVars.fonts.fontLetterSpacing2};
      }
    `,
    h3: `
      font-family: ${commonVars.fonts.familyPrimary};
      font-size: ${commonVars.fonts.fontSize28};
      font-weight: ${commonVars.fonts.weightMedium};
      line-height: ${commonVars.fonts.fontLineHeight105};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing1};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize30};
        line-height: ${commonVars.fonts.fontLineHeight110};
        letter-spacing: ${commonVars.fonts.fontLetterSpacing3};
      }
    `,
    h4: `
      font-family: ${commonVars.fonts.familyTertiary};
      font-size: ${commonVars.fonts.fontSize26};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight120};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing4};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize30};
      }
    `,
    h5: `
      font-family: ${commonVars.fonts.familyTertiary};
      font-size: ${commonVars.fonts.fontSize24};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight120};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing4};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize26};
      }
    `,
    h6: `
      font-family: ${commonVars.fonts.familyTertiary};
      font-size: ${commonVars.fonts.fontSize20};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight120};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing4};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize22};
      }
    `,
    h7: `
      font-family: ${commonVars.fonts.familyTertiary};
      font-size: ${commonVars.fonts.fontSize16};
      font-weight: ${commonVars.fonts.weightBold};
      line-height: ${commonVars.fonts.fontLineHeight140};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing6};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize18};
        font-weight: ${commonVars.fonts.weightSemiBold};
        letter-spacing: ${commonVars.fonts.fontLetterSpacing5};
      }
    `,
    h8: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize16};
      font-weight: ${commonVars.fonts.weightBold};
      line-height: ${commonVars.fonts.fontLineHeight140};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing6};
      ${commonVars.breakpoints.sm} {
        font-family: ${commonVars.fonts.familyTertiary};
        font-size: ${commonVars.fonts.fontSize18};
    }
    `,
    h9: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize16};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight140};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing6};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize18};
      }
    `,
    eyebrowRegular: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.sizeSmall};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight140};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing7};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize14};
      }
    `,
    eyebrowSmall: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize10};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight140};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing7};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.sizeSmall};
      }
    `,
    utility: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize10};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight12};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing7};
      text-transform: ${commonVars.fonts.caseUpper};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.sizeSmall};
        line-height: ${commonVars.fonts.fontLineHeight14};
        letter-spacing: ${commonVars.fonts.fontLetterSpacing8};
      }
    `,
    utilityLarge: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize16};
      font-weight: ${commonVars.fonts.weightSemiBold};
      line-height: ${commonVars.fonts.fontLineHeight18};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing8};
      text-transform: ${commonVars.fonts.caseUpper};
    `,
    quote: `
      font-family: ${commonVars.fonts.familyTertiary};
      font-size: ${commonVars.fonts.fontSize20};
      font-style: ${commonVars.fonts.styleItalic};
      line-height: ${commonVars.fonts.fontLineHeight120};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing1};
      ${commonVars.breakpoints.sm} {
        font-size: ${commonVars.fonts.fontSize28};
      }
    `,
    body1: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize16};
      font-weight: ${commonVars.fonts.weightNormal};
      line-height: ${commonVars.fonts.fontLineHeight140};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing5};
    `,
    body2: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize14};
      font-weight: ${commonVars.fonts.weightNormal};
      line-height: ${commonVars.fonts.fontLineHeight130};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing5};
    `,
    body3: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize12};
      font-weight: ${commonVars.fonts.weightNormal};
      line-height: ${commonVars.fonts.fontLineHeight130};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing5};
    `,
    caption: `
      font-family: ${commonVars.fonts.familySecondary};
      font-size: ${commonVars.fonts.fontSize12};
      font-weight: ${commonVars.fonts.weightNormal};
      line-height: ${commonVars.fonts.fontLineHeight120};
      letter-spacing: ${commonVars.fonts.fontLetterSpacing5};
    `,
  };
};

export const commonThemeWidget = (themeBaseCommon: Omit<Theme, 'widgets'>) => ({
  Footer: {
    default: `
    ${themeBaseCommon.mixins.footerBase}
    `,
    navItems: `
    ${themeBaseCommon.mixins.footerNavItems}
    `,
  },
});

export const commonScrollbar = (commonThemeVars: Omit<Theme, 'scrollbar'>) => {
  return `
      scrollbar-color:  ${commonThemeVars.colors.grey40} ${commonThemeVars.colors.white} ;
      scrollbar-width: thin;
      ::-webkit-scrollbar {
        width: ${commonThemeVars.space.sm};
      }
      ::-webkit-scrollbar-thumb {
        background-color:${commonThemeVars.colors.grey30};
        border-radius: ${commonThemeVars.space.xs};
      }
      ::-webkit-scrollbar-track {
        background-color: ${commonThemeVars.colors.white};
      }
      :hover::-webkit-scrollbar-thumb {
        background-color: ${commonThemeVars.colors.grey40};
      } 
  `;
};

const print = `
  @media screen {
    .print-only {
      display: none!important;
    }
  }
  @media print {
    .print-only {
      display: block!important;
    },
    .no-print {
      display: none!important;
    }
    #cloudlink-chat-overlay-contact-us-button {
      display: none!important;
    }
  }
`;

export const commonThemeGlobals = `
  ${print},
  &.active-search-overlay + .new-vine-section {
    z-index:0;
    position: relative;
  }
  @keyframes spin {
    to {
        transform: rotate(360deg)
    }
  }
  @keyframes fadeDown {
    from {
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  }
  .sb-show-main.sb-main-padded {
    padding: 0;
  }
  .sb-wrapper ~ #root {
    min-height: 300px;
  }

  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .pr-0 {
    padding-right: 0 !important;
  }
  .mx-0 {
    margin-left:0 !important;
    margin-right: 0 !important;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .w-100 {
    width: 100%;
  }

  /*For story book*/
  .header-block, .footer-block{
    background: #660000;
    .base-storybook-item {
      height: 80px;
      align-items: center;
      justify-content: center;
      background: #000000;
      color: #fff;
      display: flex;
    }
  }
  .banner-block {
    background: #831D1D;
    .base-storybook-item {
      height: 500px;
      background: #313131;
      align-items: center;
      justify-content: center;
      color: #fff;
      display: flex;
    }
  }
  main {
    padding: 0.75rem 0;
  }
  .storybook-item {
    height: 200px;
    align-items: center;
    justify-content: center;
    background: #000000;
    color: #fff;
    display: flex;
    padding: 5px;
    text-align: center;
  }
  .storybook-item1 {
    height: 100px;
    align-items: center;
    justify-content: center;
    background: #000000;
    color: #fff;
    display: flex;
    padding: 5px;
    text-align: center;
  }
  .h-150 {
    .storybook-item {
      height: 150px;
    }
  }
  .w-100 {
    width: 100%;
  }
  .richtext-background {
    display: inline-block;
    line-height: normal;
  }
  .vine-react-app {
    h1, h2, h3, h4, h5, h6, .hxl, .custom-h2-header, .h1, .h2, .h3, .h4, .h5, .h6, .h7, .h8, .h9,
    p, .body1, .body2, .bodySmall,
    .utility, .utilityLarge, .quote, blockquote, .caption {
      margin-top: 0;
      margin-bottom: 16px;
    }

    blockquote {
      margin-left: 0;
      margin-right: 0;
    }

    .eyebrowRegular, .eyebrowSmall {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }
`;
