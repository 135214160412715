import React, { memo } from 'react';
import { isBrowser } from '../../utils/isBrowser';
import { css } from '@emotion/react';

export interface TrustPilotWidgetProps {
  tags?: string;
  locale: string;
  businessUnitId?: string;
  dwDomain: string;
}

export interface CmsCustomerReviewData {
  businessUnitId: string;
  url: string;
}

export const TrustPilotWidget = memo(
  ({ tags = 'Adviser', locale, businessUnitId, dwDomain }: TrustPilotWidgetProps) => {
    const ref = React.useRef(null);

    const wrapperStyling = css`
      padding-top: 80px;
      padding-bottom: 80px;
    `;

    React.useEffect(() => {
      if (isBrowser() && (window as any).Trustpilot) {
        (window as any).Trustpilot.loadFromElement(ref.current, true);
      }
    }, []);

    return (
      <div
        css={wrapperStyling}
        ref={ref}
        className="trustpilot-widget"
        data-locale={locale}
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id={businessUnitId}
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-tags={tags}
        data-stars="1,2,3,4,5"
        data-review-languages="en"
      >
        <a href={`https://uk.trustpilot.com/review/${dwDomain}`} target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    );
  },
);
