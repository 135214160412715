import { selectUserDetails } from 'common-state';
import { globals } from 'common-ui';
import { useRecoilValue } from 'recoil';

const UK_CHECK_OUT_REDIRECT = '/jsp/checkout/uk/checkout.jsp#/shipping';
const US_CHECK_OUT_REDIRECT = '/jsp/checkout/us/index.jsp';
const LOGIN_REDIRECT = '/jsp/curity/login.jsp?checkout=true';

export const useCheckout = () => {
  const { loginType } = useRecoilValue(selectUserDetails);

  return loginType === 'hard_logged'
    ? globals.country === 'us'
      ? US_CHECK_OUT_REDIRECT
      : UK_CHECK_OUT_REDIRECT
    : LOGIN_REDIRECT;
};
