//contentful API data is bloated with unnecessary meta data so this method removes it
//while NOT changing any data or structures we still use
export function compressContentfulJson(dataRaw: any) {
  if (!dataRaw) {
    return dataRaw;
  }

  const dataCopy = JSON.parse(JSON.stringify(dataRaw));

  function isContentType(node: any) {
    return node instanceof Object && node.sys && node.sys.id;
  }

  function squash(node: any) {
    if (isContentType(node)) {
      if (node.sys.contentType) {
        const id = node.sys.contentType!.sys!.id;
        node.sys = {
          id: node.sys.id,
          contentType: {
            sys: {
              id,
            },
          },
        };
      }
      delete node.metadata;

      if (node.fields && typeof node.fields === 'object') {
        for (const key in node.fields) {
          const fieldNode = node.fields[key];
          if (Array.isArray(fieldNode)) {
            fieldNode.forEach(arrayNode => squash(arrayNode));
          } else if (fieldNode instanceof Object) {
            squash(fieldNode);
          } else {
          }
        }
      }
    }
  }
  squash(dataCopy);
  return dataCopy;
}
