import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';

export type ButtonProps = {
  children: React.ReactNode;
  kind?: ButtonThemeType;
  //so we can pass through any other native button attribute
  [wildcard: string]: any; //eslint-disable-line @typescript-eslint/no-explicit-any
};
type ButtonThemeType = undefined | 'primary' | 'secondary' | 'tertiary';

/**
 * A simple wrapper for 'button' incorporating theme styling
 */
export const Button = memo((props: ButtonProps) => {
  const theme = useTheme();
  const { children, kind = 'primary', ...allOtherProps } = props;
  let button;
  switch (kind) {
    case 'primary':
    default:
      button = css`
        && {
          ${theme?.mixins?.btnPrimary}
        }
      `;
      break;
    case 'secondary':
      button = css`
        && {
          ${theme.mixins.btnSecondary}
        }
      `;
      break;
    case 'tertiary':
      button = css`
        && {
          ${theme.mixins.btnTertiary}
        }
      `;
      break;
  }

  return (
    <button css={button} {...allOtherProps}>
      {children}
    </button>
  );
});
