import { atom, selector } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { globals } from 'common-ui';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import { AuthDetails } from 'tsconfig/types.d';
import { isBrowser } from '../helpers/utils';
import { getRecoil, setRecoil } from 'recoil-nexus';

const commerceApiExtraConfig = (publicCsrfValue = '') => ({
  webApiExtraHeaders: {
    [globals.authAndWebApiCsrfHeaderName as string]: publicCsrfValue,
  },
});

//Atom
//----------------------------------------------------------------------
export const authAtom = atom<AuthDetails>({
  key: 'authAtom',
  default: {
    isInitialised: false,
    publicCsrf: '',
  },
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectAuthDetails = selector({
  key: 'selectAuthDetails',
  get: ({ get }) => {
    const authDetails = get(authAtom);
    return authDetails;
  },
});

//Hooks
//----------------------------------------------------------------------
export const useInitAuthState = () => {
  return async () => {
    await fetchAuthToken();
  };
};

//Helper functions
//----------------------------------------------------------------------

export const fetchAuthToken = async () => {
  if (!isBrowser()) {
    console.log('useGetAuthToken skipping as not in browser');
    return;
  }

  try {
    const authAtomState = getRecoil(authAtom);
    if (!authAtomState.isInitialised) {
      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig());
      let token = await commerceApi.Auth.reload();

      if (!token || !token.csrf) {
        //TODO: not when logged in
        token = await commerceApi.Auth.getAnonymousToken();
      }
      const newAuthValue = formatTokenResponseToAuthAtomState(token);
      setRecoil(authAtom, newAuthValue);
      return newAuthValue;
    }
  } catch (e) {
    console.log('API ERROR', e);
  }
};

export const reloadAuthToken = async () => {
  if (!isBrowser()) {
    console.log('useReloadToken skipping as not in browser');
    return;
  }

  try {
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig());
    const token = await commerceApi.Auth.reload();
    const newAuthValue = formatTokenResponseToAuthAtomState(token);
    setRecoil(authAtom, newAuthValue);
    return newAuthValue;
  } catch (e) {
    console.log('API ERROR', e);
    throw e;
  }
};

export const getAnonymousToken = async () => {
  if (!isBrowser()) {
    console.log('useReloadToken skipping as not in browser');
    return;
  }

  try {
    const authAtomState = getRecoil(authAtom);
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
    const token = await commerceApi.Auth.getAnonymousToken();
    const newAuthValue = formatTokenResponseToAuthAtomState(token);
    setRecoil(authAtom, newAuthValue);
    return newAuthValue;
  } catch (e) {
    console.log('API ERROR', e);
    throw e;
  }
};

export const refreshAuthToken = async () => {
  if (!isBrowser()) {
    console.log('useReloadToken skipping as not in browser');
    return;
  }

  try {
    const authAtomState = getRecoil(authAtom);
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
    // call refresh endpoint
    await commerceApi.Auth.refresh();
  } catch (e) {
    console.log('API ERROR', e);
    throw e;
  }
};

export function formatTokenResponseToAuthAtomState(token: any) {
  return {
    isInitialised: true,
    publicCsrf: token.csrf,
    expiryDate: token.expires_in && new Date(Date.now() + token.expires_in),
  };
}
