import React, { useState, useEffect, memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { MenuBannerProps } from 'tsconfig/types';
import { inspectorMode } from 'common-ui';

export const CmsMenuBanner = memo(({ contentItem, dlClickEventHandler }: MenuBannerProps) => {
  const theme = useTheme();
  const { menuBannerImage, menuBannerUrl } = contentItem.fields || {};
  const widgetTheme = theme.widgets.CmsMenuBanner || {};
  const menuBannerContainer = css`
      ${widgetTheme.wrapper}
    `,
    imgResponsive = css`
      ${widgetTheme.imgResponsive}
    `;
  //access location.href in useEffect so that server side render doesnt cause hydration fails
  const [currentProtocol, setCurrentProtocol] = useState('');
  useEffect(() => {
    setCurrentProtocol(window.location.protocol);
  }, []);

  return (
    <div css={menuBannerContainer} className="menu-banner-image">
      <a
        href={menuBannerUrl}
        data-event="menuBannerImageClick"
        data-section={`menu banner image:${menuBannerImage?.fields?.title}`}
        data-action="menu banner image"
        data-name="navigation menu banner image"
        data-text={menuBannerImage?.fields?.title}
        onClick={() => dlClickEventHandler && dlClickEventHandler(menuBannerImage?.fields?.title ?? '')}
      >
        <img
          css={imgResponsive}
          src={currentProtocol + menuBannerImage?.fields?.file?.url}
          alt={menuBannerImage?.fields?.title}
          title={menuBannerImage?.fields?.title}
          className="nav-banner-img"
          {...inspectorMode(contentItem?.sys.id, 'menuBannerImage')}
        />
      </a>
    </div>
  );
});
