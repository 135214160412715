/*
A React wrapper for the init data fetch for atoms
Used currently in Next to init data within the main _app
*/
import React from 'react';
import { useInitCoreAppState } from '../hooks/useInitCoreAppState';
import { useEffectOnce } from 'common-ui';
import { useRecoilCallback } from 'recoil';
import { notificationsAtom, NotificationState } from '../atoms/notificationsAtom';

export interface RecoilInitProps {
  children?: React.ReactNode;
  apiConfig?: Record<string, any>;
}

const expirationCheckInterval = 100;

export const RecoilInit = ({ children }: RecoilInitProps) => {
  const initCoreAppState = useInitCoreAppState();
  //Special case to update recoil state outside of react (so we can process notifications in the setInterval)
  //https://github.com/facebookexperimental/Recoil/issues/289
  //https://github.com/facebookexperimental/Recoil/issues/289
  const getRecoil = useRecoilCallback(({ snapshot }) => (atom: any) => {
    return snapshot.getLoadable(atom).contents;
  });
  const setRecoil = useRecoilCallback(({ transact_UNSTABLE }) => (atom: any, valOrUpdater) => {
    transact_UNSTABLE(({ set }) => {
      set(atom, valOrUpdater);
    });
  });

  useEffectOnce(() => {
    initCoreAppState();
    // testAuthInit();

    setInterval(() => {
      const currentNotifications = getRecoil(notificationsAtom);
      const timeNow = new Date().getTime();
      const updatedNotifications = currentNotifications.filter(
        (notification: NotificationState) => notification.expires > timeNow,
      );
      if (currentNotifications.length !== updatedNotifications.length) {
        setRecoil(notificationsAtom, updatedNotifications);
      }
    }, expirationCheckInterval);
  });
  return <>{children}</>;
};
