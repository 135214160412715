// Wrapper for the next apps own API
import axios from 'axios';

export type WebAuthConfig = {
  urlPrefix: string;
  extraHeaders?: Record<string, string>;
  //TODO: review if removed in error?
  // brandDomain: string;
  host: string;
};

export const initWebAuthApi = (config: WebAuthConfig) => {
  async function asyncRequest(requestType: string, url: string, data = {}) {
    const requestConfig: any = {
      method: requestType,
      url: `${config.urlPrefix}/webauth/${url}`,
      headers: {
        siteorigin: config.host,
        origin: config.host,
        'x-dw-brand-domain': config.host,
        //The public CSRF value is part of the token auth security and is set via a custom header
        //that is specific to each brand
        // [globals.authAndWebApiCsrfHeaderName as string]: publicCsrfValue,

        ...config.extraHeaders,
      },
    };
    if (data) {
      requestConfig.data = data;
    }

    const response = await axios.request(requestConfig);
    return response.data;
  }

  return {
    Auth: {
      //creates a new anonymous access token (cookie) which will require a new cart etc
      getAnonymousToken: async () =>
        await asyncRequest('POST', `anonymous/token`, {
          tokenType: 'cart',
        }),

      //returns existing token. if csrf value is missing that means session has expired. In that case;
      //  anon user will need to call getAnonymousToken()
      //  logged user will need to call refresh()
      reload: async () =>
        await asyncRequest('POST', `pagereload`, {
          type: 'cart',
        }),

      //refreshes the access token (cookie) for logged in user (unlike anon user this allows cart to be retained)
      refresh: async () => await asyncRequest('POST', `refresh`),
    },
  };
};
