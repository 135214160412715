import React, { useState, useEffect, memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { Icon } from '../../../Icon/Icon';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { globals } from '../../../../utils/globals';
import { handleDLClickEvent } from '../../../../data-layer/handleDLClickEvent';
import Link from 'next/link';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectAddToCartNotification } from 'common-state';
import { useToggleClass } from '../../../../hooks/useToggleClass';
import MiniCartModal from '../../../MiniCart/MiniCartModal';
import { NotificationType, notificationsAtom } from 'common-state/src/atoms/notificationsAtom';

export type HeaderCartProps = {
  itemsCount: number;
  hasQuantity: boolean;
  total: number;
};

export const HeaderCart = memo((props: HeaderCartProps) => {
  const theme = useTheme();

  const [overlay, setOverlay] = useState<boolean>(false);
  const [hasMiniCartBtn, setHasMiniCartBtn] = useState<boolean>(false);
  const notifications = useRecoilValue(selectAddToCartNotification);
  const hasAddToCartMessage = !!notifications;
  const { toggledElemRef: popupRef } = useToggleClass(
    'fadedown',
    'fadeout',
    hasAddToCartMessage && !globals.enableNewMiniCart,
  );
  const setNotification = useSetRecoilState(notificationsAtom);

  const { itemsCount, hasQuantity, total } = props;
  const widgetTheme = theme.widgets.GlobalHeader || {};
  const wrapper = css`
    ${widgetTheme.headerCart}
  `;
  const handleCloseCart = () => {
    setOverlay(false);
  };

  useEffect(() => {
    const miniCartClose = document.getElementById('miniCartClose');
    {
      /* DSP-1204 hide extra over-layer of cart if miniCartClose button not available on brand */
    }
    setHasMiniCartBtn(Boolean(miniCartClose));
    if (miniCartClose) {
      miniCartClose.addEventListener('click', handleCloseCart as unknown as EventListener);
      return () => {
        miniCartClose.removeEventListener('click', handleCloseCart as unknown as EventListener);
      };
    }
  }, []);

  // Common attributes to be shared between Link and <a> tag for the Header Cart
  const commonAttributes = {
    className: 'header-cart-link',
    'data-event': 'headerCartClick',
    'data-section': 'header:cart',
    'data-action': 'cart',
    'data-name': 'header cart',
    'data-text': 'cart',
    onClick: () => {
      setOverlay(true);
      handleDLClickEvent('cart', 'cartIcon');
    },
  };

  const HeaderCartBody = () => {
    return (
      <>
        <div className="header-cart-icon-container">
          <Icon kind="cartNew" size="m" />
          <span className="cart-quantity">{itemsCount}</span>
        </div>
        {globals?.country !== 'us' && (
          <div className="header-cart-price-container">
            <span data-testid="header-cart-price">{formatCurrency(total)}</span>
          </div>
        )}
      </>
    );
  };

  const [enableMiniCart, setEnableMiniCart] = useState(false);

  const closeNotification = (timeout: number) => {
    setNotification([
      {
        type: NotificationType.ADD_TO_CART,
        timeout: timeout,
        expires: new Date().getTime() + timeout,
      },
    ]);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!!notifications && window.location.pathname !== '/cart') {
        setEnableMiniCart(true);
      } else if (!!notifications && window.location.pathname === '/cart') {
        closeNotification(4000);
      }
    }
  }, [notifications && notifications.type === NotificationType?.ADD_TO_CART]);

  const closeModal = () => {
    setEnableMiniCart(false);
    closeNotification(0);
  };

  return (
    <div
      data-test="widget-HeaderCart"
      className={`header-cart-count-container ${hasQuantity ? 'has-quantity' : ''}`}
      id="cart"
      css={wrapper}
    >
      {globals.useNewCartAPI ? (
        // Use Next.js Link component for internal navigation
        <Link href="/cart" {...commonAttributes}>
          <HeaderCartBody />
        </Link>
      ) : (
        // Use <a> tag for external navigation to JSP page
        // Note: Link component is designed for internal routing in Next.js
        // and does not work well for external URLs like JSP pages
        <a href="/jsp/checkout/common/shoppingcart.jsp" {...commonAttributes}>
          <HeaderCartBody />
        </a>
      )}

      {overlay && hasMiniCartBtn && !globals?.enableNewMiniCart && (
        <div className="minicart-overlay" onClick={handleCloseCart}></div>
      )}
      {/* This is mini cart ui trigger code */}

      <div className="cart-confirmation-popup" ref={popupRef}>
        <Icon kind="check" size="m" /> {notifications?.message}
      </div>

      {enableMiniCart && notifications?.miniCart && globals.enableNewMiniCart && (
        <MiniCartModal
          show={enableMiniCart}
          onClose={closeModal}
          selectedItemCode={notifications?.miniCart?.itemCode}
          qty={notifications?.miniCart?.quantity}
        />
      )}
    </div>
  );
});
