export type IconsId =
  | 'abv'
  | 'alcohol'
  | 'alert-circle-filled'
  | 'alert-circle'
  | 'amex'
  | 'apple-pay'
  | 'arrow-left'
  | 'award-winning'
  | 'back-arrow'
  | 'biodynamic'
  | 'blend-type'
  | 'blog'
  | 'bottle'
  | 'calendar-clock'
  | 'calendar'
  | 'camera'
  | 'caps-lock'
  | 'cart'
  | 'cartNew'
  | 'check'
  | 'Checkbox'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'choice-check'
  | 'choice-uncheck'
  | 'circle-chevron-left'
  | 'circle-chevron-right'
  | 'clear-circle'
  | 'close'
  | 'cork-screw'
  | 'coupons'
  | 'customer-services'
  | 'diamond'
  | 'dots-menu'
  | 'droplet'
  | 'envelope'
  | 'eye-dont-show'
  | 'eye'
  | 'facebook'
  | 'file-user'
  | 'filter'
  | 'fine-wine'
  | 'free-delivery'
  | 'gift-bold'
  | 'gift'
  | 'glass-bottle'
  | 'glass'
  | 'glasses'
  | 'google-pay'
  | 'grape'
  | 'green-tick'
  | 'hamburger-menu'
  | 'heart-solid'
  | 'heart'
  | 'heartNew-solid'
  | 'heartNew'
  | 'home'
  | 'information-circle'
  | 'instagram'
  | 'kosher'
  | 'label-scanner'
  | 'last-sold-bg'
  | 'ligntning-solid'
  | 'linkedin'
  | 'live-chat'
  | 'location'
  | 'map-pin'
  | 'mastercard-logo'
  | 'minus'
  | 'money-back-apac'
  | 'money-back'
  | 'offset-carbon'
  | 'organic'
  | 'packagePioneer'
  | 'paypal'
  | 'phone'
  | 'plus'
  | 'print'
  | 'question-circle'
  | 'radio-selected'
  | 'radio'
  | 'red-alert-circle-filled'
  | 'rossette'
  | 'sale-tag-no-text'
  | 'sale-tag'
  | 'saving'
  | 'search'
  | 'star-solid'
  | 'star'
  | 'subscribe-save'
  | 'sustainable'
  | 'thumbs-down-solid'
  | 'thumbs-down'
  | 'thumbs-up-solid'
  | 'thumbs-up'
  | 'tick-active-circle'
  | 'tick-circle'
  | 'ticket'
  | 'trash'
  | 'truck-apac'
  | 'truck'
  | 'twitter'
  | 'units'
  | 'unlimited'
  | 'unltCheckIcon'
  | 'user'
  | 'userNew'
  | 'vegan'
  | 'vegetarian'
  | 'visa-logo'
  | 'wine-body'
  | 'wine-colour'
  | 'wine-glass'
  | 'xTwitter'
  | 'youtube'
  | 'zoom';

export type IconsKey =
  | 'Abv'
  | 'Alcohol'
  | 'AlertCircleFilled'
  | 'AlertCircle'
  | 'Amex'
  | 'ApplePay'
  | 'ArrowLeft'
  | 'AwardWinning'
  | 'BackArrow'
  | 'Biodynamic'
  | 'BlendType'
  | 'Blog'
  | 'Bottle'
  | 'CalendarClock'
  | 'Calendar'
  | 'Camera'
  | 'CapsLock'
  | 'Cart'
  | 'CartNew'
  | 'Check'
  | 'Checkbox'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'ChoiceCheck'
  | 'ChoiceUncheck'
  | 'CircleChevronLeft'
  | 'CircleChevronRight'
  | 'ClearCircle'
  | 'Close'
  | 'CorkScrew'
  | 'Coupons'
  | 'CustomerServices'
  | 'Diamond'
  | 'DotsMenu'
  | 'Droplet'
  | 'Envelope'
  | 'EyeDontShow'
  | 'Eye'
  | 'Facebook'
  | 'FileUser'
  | 'Filter'
  | 'FineWine'
  | 'FreeDelivery'
  | 'GiftBold'
  | 'Gift'
  | 'GlassBottle'
  | 'Glass'
  | 'Glasses'
  | 'GooglePay'
  | 'Grape'
  | 'GreenTick'
  | 'HamburgerMenu'
  | 'HeartSolid'
  | 'Heart'
  | 'HeartNewSolid'
  | 'HeartNew'
  | 'Home'
  | 'InformationCircle'
  | 'Instagram'
  | 'Kosher'
  | 'LabelScanner'
  | 'LastSoldBg'
  | 'LigntningSolid'
  | 'Linkedin'
  | 'LiveChat'
  | 'Location'
  | 'MapPin'
  | 'MastercardLogo'
  | 'Minus'
  | 'MoneyBackApac'
  | 'MoneyBack'
  | 'OffsetCarbon'
  | 'Organic'
  | 'PackagePioneer'
  | 'Paypal'
  | 'Phone'
  | 'Plus'
  | 'Print'
  | 'QuestionCircle'
  | 'RadioSelected'
  | 'Radio'
  | 'RedAlertCircleFilled'
  | 'Rossette'
  | 'SaleTagNoText'
  | 'SaleTag'
  | 'Saving'
  | 'Search'
  | 'StarSolid'
  | 'Star'
  | 'SubscribeSave'
  | 'Sustainable'
  | 'ThumbsDownSolid'
  | 'ThumbsDown'
  | 'ThumbsUpSolid'
  | 'ThumbsUp'
  | 'TickActiveCircle'
  | 'TickCircle'
  | 'Ticket'
  | 'Trash'
  | 'TruckApac'
  | 'Truck'
  | 'Twitter'
  | 'Units'
  | 'Unlimited'
  | 'UnltCheckIcon'
  | 'User'
  | 'UserNew'
  | 'Vegan'
  | 'Vegetarian'
  | 'VisaLogo'
  | 'WineBody'
  | 'WineColour'
  | 'WineGlass'
  | 'XTwitter'
  | 'Youtube'
  | 'Zoom';

export enum Icons {
  Abv = 'abv',
  Alcohol = 'alcohol',
  AlertCircleFilled = 'alert-circle-filled',
  AlertCircle = 'alert-circle',
  Amex = 'amex',
  ApplePay = 'apple-pay',
  ArrowLeft = 'arrow-left',
  AwardWinning = 'award-winning',
  BackArrow = 'back-arrow',
  Biodynamic = 'biodynamic',
  BlendType = 'blend-type',
  Blog = 'blog',
  Bottle = 'bottle',
  CalendarClock = 'calendar-clock',
  Calendar = 'calendar',
  Camera = 'camera',
  CapsLock = 'caps-lock',
  Cart = 'cart',
  CartNew = 'cartNew',
  Check = 'check',
  Checkbox = 'Checkbox',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  ChoiceCheck = 'choice-check',
  ChoiceUncheck = 'choice-uncheck',
  CircleChevronLeft = 'circle-chevron-left',
  CircleChevronRight = 'circle-chevron-right',
  ClearCircle = 'clear-circle',
  Close = 'close',
  CorkScrew = 'cork-screw',
  Coupons = 'coupons',
  CustomerServices = 'customer-services',
  Diamond = 'diamond',
  DotsMenu = 'dots-menu',
  Droplet = 'droplet',
  Envelope = 'envelope',
  EyeDontShow = 'eye-dont-show',
  Eye = 'eye',
  Facebook = 'facebook',
  FileUser = 'file-user',
  Filter = 'filter',
  FineWine = 'fine-wine',
  FreeDelivery = 'free-delivery',
  GiftBold = 'gift-bold',
  Gift = 'gift',
  GlassBottle = 'glass-bottle',
  Glass = 'glass',
  Glasses = 'glasses',
  GooglePay = 'google-pay',
  Grape = 'grape',
  GreenTick = 'green-tick',
  HamburgerMenu = 'hamburger-menu',
  HeartSolid = 'heart-solid',
  Heart = 'heart',
  HeartNewSolid = 'heartNew-solid',
  HeartNew = 'heartNew',
  Home = 'home',
  InformationCircle = 'information-circle',
  Instagram = 'instagram',
  Kosher = 'kosher',
  LabelScanner = 'label-scanner',
  LastSoldBg = 'last-sold-bg',
  LigntningSolid = 'ligntning-solid',
  Linkedin = 'linkedin',
  LiveChat = 'live-chat',
  Location = 'location',
  MapPin = 'map-pin',
  MastercardLogo = 'mastercard-logo',
  Minus = 'minus',
  MoneyBackApac = 'money-back-apac',
  MoneyBack = 'money-back',
  OffsetCarbon = 'offset-carbon',
  Organic = 'organic',
  PackagePioneer = 'packagePioneer',
  Paypal = 'paypal',
  Phone = 'phone',
  Plus = 'plus',
  Print = 'print',
  QuestionCircle = 'question-circle',
  RadioSelected = 'radio-selected',
  Radio = 'radio',
  RedAlertCircleFilled = 'red-alert-circle-filled',
  Rossette = 'rossette',
  SaleTagNoText = 'sale-tag-no-text',
  SaleTag = 'sale-tag',
  Saving = 'saving',
  Search = 'search',
  StarSolid = 'star-solid',
  Star = 'star',
  SubscribeSave = 'subscribe-save',
  Sustainable = 'sustainable',
  ThumbsDownSolid = 'thumbs-down-solid',
  ThumbsDown = 'thumbs-down',
  ThumbsUpSolid = 'thumbs-up-solid',
  ThumbsUp = 'thumbs-up',
  TickActiveCircle = 'tick-active-circle',
  TickCircle = 'tick-circle',
  Ticket = 'ticket',
  Trash = 'trash',
  TruckApac = 'truck-apac',
  Truck = 'truck',
  Twitter = 'twitter',
  Units = 'units',
  Unlimited = 'unlimited',
  UnltCheckIcon = 'unltCheckIcon',
  User = 'user',
  UserNew = 'userNew',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
  VisaLogo = 'visa-logo',
  WineBody = 'wine-body',
  WineColour = 'wine-colour',
  WineGlass = 'wine-glass',
  XTwitter = 'xTwitter',
  Youtube = 'youtube',
  Zoom = 'zoom',
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.Abv]: '61697',
  [Icons.Alcohol]: '61698',
  [Icons.AlertCircleFilled]: '61699',
  [Icons.AlertCircle]: '61700',
  [Icons.Amex]: '61701',
  [Icons.ApplePay]: '61702',
  [Icons.ArrowLeft]: '61703',
  [Icons.AwardWinning]: '61704',
  [Icons.BackArrow]: '61705',
  [Icons.Biodynamic]: '61706',
  [Icons.BlendType]: '61707',
  [Icons.Blog]: '61708',
  [Icons.Bottle]: '61709',
  [Icons.CalendarClock]: '61710',
  [Icons.Calendar]: '61711',
  [Icons.Camera]: '61712',
  [Icons.CapsLock]: '61713',
  [Icons.Cart]: '61714',
  [Icons.CartNew]: '61715',
  [Icons.Check]: '61716',
  [Icons.Checkbox]: '61717',
  [Icons.ChevronDown]: '61718',
  [Icons.ChevronLeft]: '61719',
  [Icons.ChevronRight]: '61720',
  [Icons.ChevronUp]: '61721',
  [Icons.ChoiceCheck]: '61722',
  [Icons.ChoiceUncheck]: '61723',
  [Icons.CircleChevronLeft]: '61724',
  [Icons.CircleChevronRight]: '61725',
  [Icons.ClearCircle]: '61726',
  [Icons.Close]: '61727',
  [Icons.CorkScrew]: '61728',
  [Icons.Coupons]: '61729',
  [Icons.CustomerServices]: '61730',
  [Icons.Diamond]: '61731',
  [Icons.DotsMenu]: '61732',
  [Icons.Droplet]: '61733',
  [Icons.Envelope]: '61734',
  [Icons.EyeDontShow]: '61735',
  [Icons.Eye]: '61736',
  [Icons.Facebook]: '61737',
  [Icons.FileUser]: '61738',
  [Icons.Filter]: '61739',
  [Icons.FineWine]: '61740',
  [Icons.FreeDelivery]: '61741',
  [Icons.GiftBold]: '61742',
  [Icons.Gift]: '61743',
  [Icons.GlassBottle]: '61744',
  [Icons.Glass]: '61745',
  [Icons.Glasses]: '61746',
  [Icons.GooglePay]: '61747',
  [Icons.Grape]: '61748',
  [Icons.GreenTick]: '61749',
  [Icons.HamburgerMenu]: '61750',
  [Icons.HeartSolid]: '61751',
  [Icons.Heart]: '61752',
  [Icons.HeartNewSolid]: '61753',
  [Icons.HeartNew]: '61754',
  [Icons.Home]: '61755',
  [Icons.InformationCircle]: '61756',
  [Icons.Instagram]: '61757',
  [Icons.Kosher]: '61758',
  [Icons.LabelScanner]: '61759',
  [Icons.LastSoldBg]: '61760',
  [Icons.LigntningSolid]: '61761',
  [Icons.Linkedin]: '61762',
  [Icons.LiveChat]: '61763',
  [Icons.Location]: '61764',
  [Icons.MapPin]: '61765',
  [Icons.MastercardLogo]: '61766',
  [Icons.Minus]: '61767',
  [Icons.MoneyBackApac]: '61768',
  [Icons.MoneyBack]: '61769',
  [Icons.OffsetCarbon]: '61770',
  [Icons.Organic]: '61771',
  [Icons.PackagePioneer]: '61772',
  [Icons.Paypal]: '61773',
  [Icons.Phone]: '61774',
  [Icons.Plus]: '61775',
  [Icons.Print]: '61776',
  [Icons.QuestionCircle]: '61777',
  [Icons.RadioSelected]: '61778',
  [Icons.Radio]: '61779',
  [Icons.RedAlertCircleFilled]: '61780',
  [Icons.Rossette]: '61781',
  [Icons.SaleTagNoText]: '61782',
  [Icons.SaleTag]: '61783',
  [Icons.Saving]: '61784',
  [Icons.Search]: '61785',
  [Icons.StarSolid]: '61786',
  [Icons.Star]: '61787',
  [Icons.SubscribeSave]: '61788',
  [Icons.Sustainable]: '61789',
  [Icons.ThumbsDownSolid]: '61790',
  [Icons.ThumbsDown]: '61791',
  [Icons.ThumbsUpSolid]: '61792',
  [Icons.ThumbsUp]: '61793',
  [Icons.TickActiveCircle]: '61794',
  [Icons.TickCircle]: '61795',
  [Icons.Ticket]: '61796',
  [Icons.Trash]: '61797',
  [Icons.TruckApac]: '61798',
  [Icons.Truck]: '61799',
  [Icons.Twitter]: '61800',
  [Icons.Units]: '61801',
  [Icons.Unlimited]: '61802',
  [Icons.UnltCheckIcon]: '61803',
  [Icons.User]: '61804',
  [Icons.UserNew]: '61805',
  [Icons.Vegan]: '61806',
  [Icons.Vegetarian]: '61807',
  [Icons.VisaLogo]: '61808',
  [Icons.WineBody]: '61809',
  [Icons.WineColour]: '61810',
  [Icons.WineGlass]: '61811',
  [Icons.XTwitter]: '61812',
  [Icons.Youtube]: '61813',
  [Icons.Zoom]: '61814',
};
