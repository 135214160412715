import React, { memo, useMemo } from 'react';
import get from 'lodash/get';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { CmsRichText } from '../CmsRichText/CmsRichText';
import { CmsContainer } from '../CmsContainer/CmsContainer';
import { CmsImage } from '../CmsImage/CmsImage';
import { CmsVideoComponent } from '../CmsVideoComponent/CmsVideoComponent';
import CmsColumns from '../CmsColumns/CmsColumns';
import { CmsText } from '../CmsText/CmsText';
import { CmsWidget } from '../CmsWidget/CmsWidget';
import { CmsLink } from '../CmsLink/CmsLink';
import { CarouselEntryType, ColumnEntryType, DLClickEventHandlerProps } from 'tsconfig/types.d';
import { CmsBanner } from '../CmsBanner/CmsBanner';
import { CmsAccordion } from '../CmsAccordion/CmsAccordion';
import { CmsMenuBanner } from '../CmsMenuBanner/CmsMenuBanner';
import { CmsNavigation } from '../CmsNavigation/CmsNavigation';
import { CmsArticle } from '../CmsArticle/CmsArticle';
import { CmsConditionalContainer } from '../CmsConditionalContainer/CmsConditionalContainer';
import { CmsCarousel } from '../CmsCarousel/CmsCarousel';
import { CmsProductCard } from '../CmsProductCard/CmsProductCard';

export type CmsContentSectionProps = {
  contentJsonList: any;
  category?: string;
  dlClickEventHandler?: DLClickEventHandlerProps;
};

type RenderDynamicComponentsProps = {
  componentDataList: any[];
  dlClickEventHandler?: DLClickEventHandlerProps;
};

type RenderDynamicComponentProps = {
  contentItem: any;
  dlClickEventHandler?: DLClickEventHandlerProps;
};

const RenderDynamicComponent = (props: RenderDynamicComponentProps): any => {
  const { contentItem, dlClickEventHandler } = props;
  const contentTypeId = get(contentItem, 'sys.contentType.sys.id');
  const instanceId = get(contentItem, 'sys.id');

  const dlClickEventData = (data?: string) => {
    dlClickEventHandler?.(`${contentItem?.fields?.title}:${data}`);
  };

  switch (contentTypeId) {
    case 'text':
      return <CmsText contentItem={contentItem} key={instanceId} />;

    case 'productCard':
      return <CmsProductCard contentItem={contentItem} key={instanceId} />;

    case 'widget':
      return <CmsWidget contentItem={contentItem} key={instanceId} />;

    case 'link':
      return (
        <CmsLink
          contentItem={contentItem}
          key={instanceId}
          category="main navbar link"
          dlClickEventHandler={dlClickEventHandler}
        />
      );

    case 'image':
      return <CmsImage contentItem={contentItem} key={instanceId} isNextImg width={400} height={400} />;

    case 'video':
      return <CmsVideoComponent contentItem={contentItem} key={instanceId} />;

    case 'richtext':
      return <CmsRichText contentItem={contentItem} key={instanceId} />;

    case 'banner':
      return <CmsBanner contentItem={contentItem} key={instanceId} />;

    case 'menuBanner':
      return <CmsMenuBanner contentItem={contentItem} key={instanceId} dlClickEventHandler={dlClickEventHandler} />;

    case 'accordionLinks':
      return <CmsAccordion contentItem={contentItem} key={instanceId} />;

    case 'container':
      return (
        <CmsContainer
          key={instanceId}
          backgroundColour={contentItem.fields?.backgroundColour}
          alignment={contentItem.fields?.alignment}
          pageWidthToggle={contentItem.fields?.pageWidthToggle}
        >
          <MemoizedRenderDynamicComponents componentDataList={contentItem.fields.content} />
        </CmsContainer>
      );

    case 'navigation':
      return (
        <CmsNavigation
          contentItem={contentItem}
          key={instanceId}
          className="navigation-link"
          dlClickEventHandler={dlClickEventHandler}
        >
          <MemoizedRenderDynamicComponents
            componentDataList={contentItem.fields.content}
            dlClickEventHandler={dlClickEventData}
          />
        </CmsNavigation>
      );
    case 'article':
      return <CmsArticle contentItem={contentItem} key={instanceId} />;
    case 'carousel':
      const slidesContent = contentItem.fields.slides || [];
      const slides = (slidesContent as CarouselEntryType['fields']['slides']).map(content => (
        <CmsCarousel.CmsCarouselSlide key={content?.sys.id} dataTestId={`slide-${content?.sys.id}`}>
          <MemoizedRenderDynamicComponent contentItem={content} dlClickEventHandler={dlClickEventHandler} />
        </CmsCarousel.CmsCarouselSlide>
      ));
      return (
        <CmsCarousel
          slidesPerBlock={contentItem.fields.slidesPerBlock}
          slidesToScroll={contentItem.fields.slidesToScroll}
          key={instanceId}
          dataTestId={`carousel-${contentItem.fields.entryTitle}`}
        >
          {slides}
        </CmsCarousel>
      );
    case 'columns':
      const columnsContent = contentItem.fields.content || [];
      const columns = columnsContent.map((columnContent: ColumnEntryType) => (
        <CmsColumns.CmsColumn contentItem={columnContent} key={columnContent.sys.id}>
          <MemoizedRenderDynamicComponents
            componentDataList={columnContent.fields.content}
            dlClickEventHandler={dlClickEventHandler}
          />
        </CmsColumns.CmsColumn>
      ));
      return (
        <CmsColumns contentItem={columnsContent} key={instanceId}>
          {columns}
        </CmsColumns>
      );
    case 'conditionalContainer':
      return (
        <CmsConditionalContainer contentItem={contentItem}>
          <MemoizedRenderDynamicComponents
            componentDataList={contentItem.fields.content}
            dlClickEventHandler={dlClickEventHandler}
          />
        </CmsConditionalContainer>
      );

    default:
      // console.error('matching component not found for content item ', contentTypeId);
      return undefined;
  }
};

const MemoizedRenderDynamicComponent = memo(RenderDynamicComponent);

const RenderDynamicComponents = (props: RenderDynamicComponentsProps) => {
  const { componentDataList, dlClickEventHandler } = props;
  return (
    <>
      {componentDataList?.map(item => (
        <MemoizedRenderDynamicComponent
          contentItem={item}
          dlClickEventHandler={dlClickEventHandler}
          key={item?.sys?.id}
        />
      ))}
    </>
  );
};

const MemoizedRenderDynamicComponents = memo(RenderDynamicComponents);

export const CmsContentSection = memo((props: CmsContentSectionProps) => {
  const { contentJsonList, dlClickEventHandler } = props;
  const theme = useTheme();
  const widgetTheme = theme.widgets.CmsContentSection || {};
  const Wrapper = useMemo(
    () => styled.section`
      ${widgetTheme.wrapper}
    `,
    [],
  );

  return (
    <Wrapper data-test="dynamic-components" className="dynamicComponents">
      <MemoizedRenderDynamicComponents componentDataList={contentJsonList} dlClickEventHandler={dlClickEventHandler} />
    </Wrapper>
  );
});
