import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { ContainerLayout } from '../../../layouts/ContainerLayout';
import { ContainerEntryType } from 'tsconfig/types';

export type CmsContainerProps = {
  children: React.ReactNode;
  alignment?: ContainerEntryType['fields']['alignment'];
  backgroundColour?: ContainerEntryType['fields']['backgroundColour'];
  pageWidthToggle?: ContainerEntryType['fields']['pageWidthToggle'];
};

export const CmsContainer = memo((props: CmsContainerProps) => {
  const { children, backgroundColour, alignment, pageWidthToggle } = props;
  const theme = useTheme();

  const bgColor = backgroundColour
    ? (theme.colors.bgColors as unknown as Record<string, string>)[backgroundColour]
    : 'initial';

  const container = css`
    ${theme.widgets?.CmsContainer}
    ${backgroundColour && !pageWidthToggle && `background: ${bgColor};`}
    ${backgroundColour && !pageWidthToggle && `background-color: ${backgroundColour};`}
    ${alignment && `text-align: ${alignment.toLowerCase()};`}
  `;

  return (
    <div data-test="widget-container" css={container}>
      <ContainerLayout style={{ background: bgColor, backgroundColor: backgroundColour ?? 'initial' }}>
        {children}
      </ContainerLayout>
    </div>
  );
});
