import { ProductProps } from 'tsconfig/types';
interface AccoladeProps {
  accoladeLevelDescription: string;
  accoladeOrgDescription: string;
  accoladeTypeDescription: string;
}

export const adobeProductMapData = (data: ProductProps, quantity: number) => {
  /**
   * Sets the accolades for the given data.
   * @param data - The data containing accolades.
   * @returns An array of filtered accolades or false if data.accolades is falsy.
   */
  function setAccolades(data: any) {
    if (data?.accolades) {
      const newFilteredAccolades = data?.accolades?.map((item: AccoladeProps) => ({
        accoladeDescription: item?.accoladeLevelDescription,
        accoladeNameText: item?.accoladeOrgDescription,
        accoladeCategoryText: item?.accoladeTypeDescription,
      }));

      return newFilteredAccolades;
    }
    return false;
  }

  return {
    accolades: setAccolades(data),
    attributes: {
      organic: data?.organic,
      vegan: data?.vegan,
      vegetarian: data?.vegetarian,
    },
    colour: data?.colourName ? data?.colourName : '',
    country: data?.countryName ? data?.countryName : '',
    discount: (data?.skus?.[0]?.buyersRRP - data?.skus?.[0]?.salePrice).toFixed(2),
    enPrimeur: data?.enPrimeur,
    favourited: data?.favourited !== undefined ? data?.favourited : '',
    grape: data?.grapeName ? data?.grapeName : '',
    isMixed: data?.isMixed,
    largeImage: data?.largeImage ? data?.largeImage : '',
    name: data?.name ? data?.name : '',
    noBottles: data?.skus?.[0]?.numberOfBottles ? data?.skus?.[0]?.numberOfBottles : '',
    noReviews: data?.totalReviewCount ? data?.totalReviewCount : '',
    price: data?.skus?.[0]?.buyersRRP ? data?.skus?.[0]?.buyersRRP : '',
    rating: data?.averageOverallRating ? data?.averageOverallRating : '',
    salesActivity: data?.salesActivity !== undefined ? data?.salesActivity : '',
    sku: data?.itemCode ? data?.itemCode : '',
    smallImage: data?.smallImage ? data?.smallImage : '',
    stockAmount: data?.inventoryInfo?.stockQuantity ? data?.inventoryInfo?.stockQuantity : '',
    stockAvailability: data?.inventoryInfo?.availabilityStatus ? data?.inventoryInfo?.availabilityStatus : '',
    subType: data?.productWebType ? data?.productWebType : '',
    thumbnailImage: data?.thumbnailImage ? data?.thumbnailImage : '',
    type: data?.productType ? data?.productType : '',
    units: quantity,
    vintage: data?.vintage ? data?.vintage : '',
    vppApplier: data?.skus?.[0]?.vppApplier !== undefined ? data?.skus?.[0]?.vppApplier : '',
    vppPrice: data?.skus?.[0]?.vppPrice ? data?.skus?.[0]?.vppPrice : '',
    vppQualifier: data?.skus?.[0]?.vppAllowed,
    webHeadline: data?.webHeadline ? data?.webHeadline : '',
  };
};
