import React, { memo, useCallback, useEffect, useRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { Icon } from '../../../Icon/Icon';
import { globals } from '../../../../utils/globals';
import AlgoliaSearch from '../../../Search/AlgoliaSearch';
import Grid from '../../../grid/Grid';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { useRecoilValue, selectedStateAtom } from 'common-state';

export type SearchBarProps = {
  isSticky?: boolean;
};

export const HeaderSearchBar = memo((props: SearchBarProps) => {
  const { isSticky } = props;
  const stateUpdate = useRecoilValue(selectedStateAtom);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.GlobalHeader?.headerSearchInput}
  `;
  const { screen } = useScreenLayout();

  const getSearchSuggestionPos = useCallback(() => {
    const searchBar = searchBarRef.current;
    const desktopView = ['lg', 'xl', 'xxl'].includes(screen);
    if (!searchBar) return 0;
    const searchbarContainer = searchBar.closest('#search-nav-bar') as HTMLElement;
    const searchbarGridContainer = searchBar.querySelector('.searchbar-grid-block-container') as any;
    let bottom;
    if (desktopView) {
      bottom = Math.ceil(searchbarContainer?.getBoundingClientRect().bottom);
    } else {
      bottom = Math.ceil(searchbarGridContainer?.getBoundingClientRect().bottom);
    }
    return bottom;
  }, [screen]);

  const setSearchOverLay = () => {
    const top = getSearchSuggestionPos();
    const searchOverlay = searchBarRef.current?.querySelector('.search-overlay') as HTMLElement;
    searchOverlay?.style?.setProperty('top', `${top}px`);
    return top;
  };

  const toggleSearchFocus = (focus: boolean) => {
    const searchBar = searchBarRef.current;
    if (!searchBar) return;
    const navbarCenterElem = searchBar.closest('.navbar-center-container');
    const navbarLeftElem = searchBar.closest('.navbar-center-container')?.previousElementSibling;
    const newHeaderReactAppElem = document.querySelector('#newHeaderReactApp') as HTMLElement;

    if (focus) {
      navbarCenterElem?.classList.add('input-on-focus');
      navbarLeftElem?.classList.add('input-on-focus');
      newHeaderReactAppElem?.classList.add('active-search-overlay');
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      setSearchOverLay();
    } else {
      navbarCenterElem?.classList.remove('input-on-focus');
      navbarLeftElem?.classList.remove('input-on-focus');
      newHeaderReactAppElem?.classList.remove('active-search-overlay');
      document.getElementsByTagName('html')[0].style.overflow = 'visible';
    }

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        navbarCenterElem?.classList.remove('input-on-focus');
        navbarLeftElem?.classList.remove('input-on-focus');
        newHeaderReactAppElem?.classList.remove('active-search-overlay');
        document.getElementsByTagName('html')[0].style.overflow = 'visible';
        (document.activeElement as HTMLElement).blur();
      }
    });
  };

  useEffect(() => {
    const searchBox = searchBarRef.current?.querySelector('.aa-Input');
    const listenFocus = () => toggleSearchFocus(true);
    searchBox?.addEventListener('focus', listenFocus);
    return () => searchBox?.removeEventListener('focus', listenFocus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarRef.current, stateUpdate]);

  const cancelSearch = () => toggleSearchFocus(false);

  const mobileSearch = () => {
    toggleSearchFocus(true);
    const searchForm = searchBarRef.current?.querySelector('.aa-Form') as HTMLFormElement;
    searchForm?.reset();
  };

  return (
    <div ref={searchBarRef} css={wrapper} className="header-search-bar">
      <span className="search-icon" onClick={mobileSearch}>
        <Icon kind="search" size="m" />
      </span>
      <div className="searchbar-grid-block-container">
        {globals?.algoliaIndexName ? (
          <AlgoliaSearch isSticky={isSticky} onCancelSearch={cancelSearch} setSearchOverLay={setSearchOverLay} />
        ) : (
          // * The Below code is a temporary fix for algolia issue will be removed in future
          <Grid align="center" className="searchbar-grid">
            <Grid.Col className="w-100">
              <div className="searchbar-input-container">
                <input
                  type="search"
                  placeholder="Search for products"
                  name="search"
                  className="temp"
                  autoComplete="off"
                />
                <span className="prefix-icon">
                  <Icon kind="search" size="20px" />
                </span>
              </div>
            </Grid.Col>
          </Grid>
        )}
      </div>
    </div>
  );
});
