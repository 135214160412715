export const CONTENTFUL_APP_URL = 'https://app.contentful.com/';

export const CURRENCIES = {
  AUD: 'AUD',
  NZD: 'NZD',
  GBP: 'GBP',
  USD: 'USD',
};

export const CURRENCY_SYMBOL = {
  AUD: '$',
  NZD: '$',
  GBP: '£',
  USD: '$',
};

export const NEW_RELIC_SRC: Record<string, string> = {
  'website-uklait-uat': 'newRelicUat',
  'website-uklait-prod': 'newRelicProd',
};

export const MIN_PRODUCT_QUANTITY_MESSAGE = "Minimum quantity of '1' required";

export const MAX_PRODUCT_LIMIT_MESSAGE = 'You may only add a maximum of 99 of this product';

export const OUT_OF_STOCK_MESSAGE = 'Unfortunately we don’t have more of this item in stock';

export const PRODUCTTYPE_TO_NOT_SHOW_BOTTLE_COUNT = [
  'Service',
  'Ticket',
  'Voucher',
  'Sundry',
  'Literature',
  'Packaging',
];

export const ITEMCLASSIFICATION_TYPE_GIFT = 'gift';

export const STATIC_WINE_PATHNAME = '/wine';

export const PDP_PLP_MAPPING_LINKS: any = {
  Albariño: 'albarino',
  Aligoté: 'aligote',
  Argentina: 'argentinian',
  Australia: 'australian',
  Austria: 'austrian',
  Bulgaria: 'bulgarian',
  Carménère: 'carmenere',
  'Carménère-based blend': 'carmenere-based-blend',
  Castelão: 'castelao',
  'Castelão-based blend': 'castelao-based-blend',
  Chile: 'chilean',
  China: 'chinese',
  Croatia: 'croatian',
  'Czech Republic': 'czech',
  Denmark: 'danish',
  'Fernão Pires': 'fernao-pires',
  'Fernão Pires-based blend': 'fernao-pires-based-blend',
  Finland: 'finnish',
  France: 'french',
  Georgia: 'georgian',
  Gewürztraminer: 'gewurztraminer',
  Graševina: 'grasevina',
  'Graševina-based blend': 'grasevina-based-blend',
  'Grüner Veltliner': 'gruner-veltliner',
  Hungary: 'hungarian',
  Italy: 'italian',
  Jacquère: 'jacquere',
  Japan: 'japanese',
  Lebanon: 'lebanese',
  Mexico: 'mexican',
  Moldova: 'moldovan',
  Négrette: 'negrette',
  "Nero d'Avola": 'nero-d-avola',
  'Not Applicable': 'wine',
  'Unspecified Country': 'wine',
  undefined: 'wine',
  null: 'wine',
  wine: 'wine',
  'Other Red': 'red',
  'Pedro Ximénez': 'pedro-ximenez',
  Poland: 'polish',
  Portugal: 'portuguese',
  Romania: 'romanian',
  'Rosé blend': 'rose-blend',
  Sémillon: 'semillon',
  'Sémillon-based blend': 'semillon-based-blend',
  'South Africa': 'south-african',
  Spain: 'spanish',
  Torrontés: 'torrontes',
  'United Kingdom': 'british',
  'United States': 'american',
  Uruguay: 'uruguayan',
};

export const NEW_CART_PAGE = '/cart';

export const OLD_CART_PAGE = '/jsp/checkout/common/shoppingcart.jsp';
