// This is a generated file - DO NOT EDIT DIRECTLY

export const icons = {
  abv: ` &:before{ content: "\\f101"; } `,
  alcohol: ` &:before{ content: "\\f102"; } `,
  'alert-circle-filled': ` &:before{ content: "\\f103"; } `,
  'alert-circle': ` &:before{ content: "\\f104"; } `,
  amex: ` &:before{ content: "\\f105"; } `,
  'apple-pay': ` &:before{ content: "\\f106"; } `,
  'arrow-left': ` &:before{ content: "\\f107"; } `,
  'award-winning': ` &:before{ content: "\\f108"; } `,
  'back-arrow': ` &:before{ content: "\\f109"; } `,
  biodynamic: ` &:before{ content: "\\f10a"; } `,
  'blend-type': ` &:before{ content: "\\f10b"; } `,
  blog: ` &:before{ content: "\\f10c"; } `,
  bottle: ` &:before{ content: "\\f10d"; } `,
  'calendar-clock': ` &:before{ content: "\\f10e"; } `,
  calendar: ` &:before{ content: "\\f10f"; } `,
  camera: ` &:before{ content: "\\f110"; } `,
  'caps-lock': ` &:before{ content: "\\f111"; } `,
  cart: ` &:before{ content: "\\f112"; } `,
  cartNew: ` &:before{ content: "\\f113"; } `,
  check: ` &:before{ content: "\\f114"; } `,
  Checkbox: ` &:before{ content: "\\f115"; } `,
  'chevron-down': ` &:before{ content: "\\f116"; } `,
  'chevron-left': ` &:before{ content: "\\f117"; } `,
  'chevron-right': ` &:before{ content: "\\f118"; } `,
  'chevron-up': ` &:before{ content: "\\f119"; } `,
  'choice-check': ` &:before{ content: "\\f11a"; } `,
  'choice-uncheck': ` &:before{ content: "\\f11b"; } `,
  'circle-chevron-left': ` &:before{ content: "\\f11c"; } `,
  'circle-chevron-right': ` &:before{ content: "\\f11d"; } `,
  'clear-circle': ` &:before{ content: "\\f11e"; } `,
  close: ` &:before{ content: "\\f11f"; } `,
  'cork-screw': ` &:before{ content: "\\f120"; } `,
  coupons: ` &:before{ content: "\\f121"; } `,
  'customer-services': ` &:before{ content: "\\f122"; } `,
  diamond: ` &:before{ content: "\\f123"; } `,
  'dots-menu': ` &:before{ content: "\\f124"; } `,
  droplet: ` &:before{ content: "\\f125"; } `,
  envelope: ` &:before{ content: "\\f126"; } `,
  'eye-dont-show': ` &:before{ content: "\\f127"; } `,
  eye: ` &:before{ content: "\\f128"; } `,
  facebook: ` &:before{ content: "\\f129"; } `,
  'file-user': ` &:before{ content: "\\f12a"; } `,
  filter: ` &:before{ content: "\\f12b"; } `,
  'fine-wine': ` &:before{ content: "\\f12c"; } `,
  'free-delivery': ` &:before{ content: "\\f12d"; } `,
  'gift-bold': ` &:before{ content: "\\f12e"; } `,
  gift: ` &:before{ content: "\\f12f"; } `,
  'glass-bottle': ` &:before{ content: "\\f130"; } `,
  glass: ` &:before{ content: "\\f131"; } `,
  glasses: ` &:before{ content: "\\f132"; } `,
  'google-pay': ` &:before{ content: "\\f133"; } `,
  grape: ` &:before{ content: "\\f134"; } `,
  'green-tick': ` &:before{ content: "\\f135"; } `,
  'hamburger-menu': ` &:before{ content: "\\f136"; } `,
  'heart-solid': ` &:before{ content: "\\f137"; } `,
  heart: ` &:before{ content: "\\f138"; } `,
  'heartNew-solid': ` &:before{ content: "\\f139"; } `,
  heartNew: ` &:before{ content: "\\f13a"; } `,
  home: ` &:before{ content: "\\f13b"; } `,
  'information-circle': ` &:before{ content: "\\f13c"; } `,
  instagram: ` &:before{ content: "\\f13d"; } `,
  kosher: ` &:before{ content: "\\f13e"; } `,
  'label-scanner': ` &:before{ content: "\\f13f"; } `,
  'last-sold-bg': ` &:before{ content: "\\f140"; } `,
  'ligntning-solid': ` &:before{ content: "\\f141"; } `,
  linkedin: ` &:before{ content: "\\f142"; } `,
  'live-chat': ` &:before{ content: "\\f143"; } `,
  location: ` &:before{ content: "\\f144"; } `,
  'map-pin': ` &:before{ content: "\\f145"; } `,
  'mastercard-logo': ` &:before{ content: "\\f146"; } `,
  minus: ` &:before{ content: "\\f147"; } `,
  'money-back-apac': ` &:before{ content: "\\f148"; } `,
  'money-back': ` &:before{ content: "\\f149"; } `,
  'offset-carbon': ` &:before{ content: "\\f14a"; } `,
  organic: ` &:before{ content: "\\f14b"; } `,
  packagePioneer: ` &:before{ content: "\\f14c"; } `,
  paypal: ` &:before{ content: "\\f14d"; } `,
  phone: ` &:before{ content: "\\f14e"; } `,
  plus: ` &:before{ content: "\\f14f"; } `,
  print: ` &:before{ content: "\\f150"; } `,
  'question-circle': ` &:before{ content: "\\f151"; } `,
  'radio-selected': ` &:before{ content: "\\f152"; } `,
  radio: ` &:before{ content: "\\f153"; } `,
  'red-alert-circle-filled': ` &:before{ content: "\\f154"; } `,
  rossette: ` &:before{ content: "\\f155"; } `,
  'sale-tag-no-text': ` &:before{ content: "\\f156"; } `,
  'sale-tag': ` &:before{ content: "\\f157"; } `,
  saving: ` &:before{ content: "\\f158"; } `,
  search: ` &:before{ content: "\\f159"; } `,
  'star-solid': ` &:before{ content: "\\f15a"; } `,
  star: ` &:before{ content: "\\f15b"; } `,
  'subscribe-save': ` &:before{ content: "\\f15c"; } `,
  sustainable: ` &:before{ content: "\\f15d"; } `,
  'thumbs-down-solid': ` &:before{ content: "\\f15e"; } `,
  'thumbs-down': ` &:before{ content: "\\f15f"; } `,
  'thumbs-up-solid': ` &:before{ content: "\\f160"; } `,
  'thumbs-up': ` &:before{ content: "\\f161"; } `,
  'tick-active-circle': ` &:before{ content: "\\f162"; } `,
  'tick-circle': ` &:before{ content: "\\f163"; } `,
  ticket: ` &:before{ content: "\\f164"; } `,
  trash: ` &:before{ content: "\\f165"; } `,
  'truck-apac': ` &:before{ content: "\\f166"; } `,
  truck: ` &:before{ content: "\\f167"; } `,
  twitter: ` &:before{ content: "\\f168"; } `,
  units: ` &:before{ content: "\\f169"; } `,
  unlimited: ` &:before{ content: "\\f16a"; } `,
  unltCheckIcon: ` &:before{ content: "\\f16b"; } `,
  user: ` &:before{ content: "\\f16c"; } `,
  userNew: ` &:before{ content: "\\f16d"; } `,
  vegan: ` &:before{ content: "\\f16e"; } `,
  vegetarian: ` &:before{ content: "\\f16f"; } `,
  'visa-logo': ` &:before{ content: "\\f170"; } `,
  'wine-body': ` &:before{ content: "\\f171"; } `,
  'wine-colour': ` &:before{ content: "\\f172"; } `,
  'wine-glass': ` &:before{ content: "\\f173"; } `,
  xTwitter: ` &:before{ content: "\\f174"; } `,
  youtube: ` &:before{ content: "\\f175"; } `,
  zoom: ` &:before{ content: "\\f176"; } `,
};
