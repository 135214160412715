import { ColorsConfig, FontsConfig, SpaceConfig, ThemeBreakpointWidthVars, ThemeGrid } from 'tsconfig/types';

// * Fonts
const FONT_SIZE_DEFAULT = '16px';
const FONTS_CONFIG: FontsConfig = {
  familyPrimary: `serif`,
  familySecondary: `sans-serif`,
  familyTertiary: `serif`,
  sizeRoot: FONT_SIZE_DEFAULT, //used in page root
  sizeSmall: '14px',
  sizeDefault: FONT_SIZE_DEFAULT,
  sizeLarge: '20px',
  sizeH1: '36px',
  sizeH2: '28px',
  sizeH3: '20px',
  sizeH4: '16px',
  sizeH5: '16px',
  sizeH6: '24px',
  sizeH7: '20px',
  sizeH8: '22px',
  sizeH9: '26px',
  sizeH10: '30px',

  weightLight: '300',
  weightNormal: '400',
  weightMedium: '500',
  weightBold: '700',
  weightSemiBold: '600',
  fontSize64: '64px',
  fontSize60: '60px',
  fontSize58: '58px',
  fontSize45: '45px',
  fontSize48: '48px',
  fontSize44: '44px',
  fontSize40: '40px',
  fontSize38: '38px',
  fontSize36: '36px',
  fontSize34: '34px',
  fontSize32: '32px',
  fontSize30: '30px',
  fontSize28: '28px',
  fontSize26: '26px',
  fontSize24: '24px',
  fontSize22: '22px',
  fontSize20: '20px',
  fontSize18: '18px',
  fontSize16: '16px',
  fontSize14: '14px',
  fontSize12: '12px',
  fontSize10: '10px',
  fontLineHeight90: '90%',
  fontLineHeight95: '95%',
  fontLineHeight100: '100%',
  fontLineHeight105: '105%',
  fontLineHeight110: '110%',
  fontLineHeight115: '115%',
  fontLineHeight120: '120%',
  fontLineHeight125: '125%',
  fontLineHeight130: '130%',
  fontLineHeight140: '140%',
  fontLineHeight18: '18px',
  fontLineHeight16: '16px',
  fontLineHeight14: '14px',
  fontLineHeight12: '12px',
  fontLetterSpacing1: '-0.04em',
  fontLetterSpacing2: '-0.03em',
  fontLetterSpacing3: '-0.02em',
  fontLetterSpacing4: '-0.01em',
  fontLetterSpacing5: '0.00em',
  fontLetterSpacing6: '0.01em',
  fontLetterSpacing7: '0.04em',
  fontLetterSpacing8: '0.03em',
  fontLetterSpacing9: '0.02em',
  fontLetterSpacing10: '-0.05em',
  caseUpper: 'uppercase',
  caseStrikeThrough: 'line-through',
  styleItalic: 'italic',
};

// * Colors
export const COLORS_CONFIG: ColorsConfig = {
  error: '#D7363B',
  errorLight: '#FFF0F1',
  success: '#448020',
  successLight: '#F0FAF2',
  warning: '#EB8308',
  warningLight: '#FFEED9',
  infoLight: '#E8F4FD',
  specialLight: '#FFFDF2',
  white: '#FFFFFF',
  grey00: '#EDEDED',
  grey10: '#F6F6F6',
  grey20: '#CCCCCC',
  grey30: '#969696',
  grey40: '#767676',
  grey50: '#222222',
  grey60: '#616161',
  grey70: '#494949',
  black: '#000000',
  transparent: 'transparent',
};

// * Breakpoints
const BREAKPOINTS_CONFIG: ThemeBreakpointWidthVars = {
  xs: 375,
  sm: 768,
  md: 1024,
  lg: 1230,
  xl: 1366,
  xxl: 1920,
};

// * Space
const SPACE_CONFIG: SpaceConfig = {
  //for padding/margins
  xs: '5px',
  sm: '7px',
  md: '10px',
  lg: '15px',
  xl: '20px',
  xl2: '30px',
  xl3: '40px',
  xl4: '50px',
  xl5: '80px',
  xl6: '100px',
};

// * grid
const GRID_CONFIG: ThemeGrid = {
  container: {
    xxl: 1680,
  },
  margin: {
    xs: 32,
    sm: 64,
    md: 64,
    lg: 144,
    xl: 144,
  },
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  },
  gutter: {
    xs: 16,
    sm: 16,
    md: 16,
    lg: 24,
    xl: 24,
    xxl: 24,
  },
};

export const commonThemeVars = {
  meta: {
    name: 'Common Theme',
  },
  assets: {},
  icons: {},
  fonts: FONTS_CONFIG,
  colors: COLORS_CONFIG,
  breakpointWidths: BREAKPOINTS_CONFIG,
  space: SPACE_CONFIG,
  grid: GRID_CONFIG,
};
