//This is a temp placeholder for the CMS Image component
//TODO: implement this properly
import React from 'react';
import { css, useTheme } from '@emotion/react';
import { ImageEntryType } from 'tsconfig/types.d';
import Image from 'next/image';
import { inspectorMode } from 'common-ui';

export type CmsImageProps = {
  contentItem?: ImageEntryType;
  //image defaults to filling the width of its container (while mainting aspect ratio)
  disableAutoSize?: boolean;
  isNextImg?: boolean;
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  blurDataURL?: string;
  placeholder?: 'empty' | 'blur' | `data:image/${string}` | undefined;
};

export const CmsImage = (props: CmsImageProps) => {
  const {
    disableAutoSize,
    contentItem,
    isNextImg,
    height,
    width,
    sizes,
    fill = false,
    blurDataURL,
    placeholder = 'empty',
  } = props;

  const theme = useTheme();

  if (!contentItem) {
    return null;
  }

  const { largeDeviceImage, smallDeviceImage, altText } = contentItem.fields;
  const wrapper = css`
    ${fill ? 'position: relative; width: 100%; height: 100%;' : ''}
    img {
      ${!disableAutoSize && 'width: 100%; height: auto;'}
    }
  `;

  const mediaXs = theme.breakpoints.xs ? theme.breakpoints.xs.replace('@media', '') : '';
  const mediaMd = theme.breakpoints.md ? theme.breakpoints.md.replace('@media', '') : '';

  const conditionalImageProps = fill ? { fill } : { width, height, layout: 'responsive' };

  return (
    <div data-test="widget-CmsImage" css={wrapper} className="cms-image-container">
      {isNextImg ? (
        <Image
          src={largeDeviceImage?.fields?.file?.url || ''}
          alt={altText || 'banner'}
          className="banner-img"
          sizes={sizes}
          objectFit="cover"
          blurDataURL={blurDataURL}
          placeholder={placeholder}
          {...conditionalImageProps}
        />
      ) : (
        <picture {...inspectorMode(contentItem?.sys?.id, 'largeDeviceImage' || 'smallDeviceImage')}>
          <source media={mediaMd} srcSet={largeDeviceImage?.fields?.file?.url} />
          <source media={mediaXs} srcSet={smallDeviceImage?.fields?.file?.url} width={'100%'} height={'100%'} />
          <img
            loading="lazy"
            src={largeDeviceImage?.fields?.file?.url}
            alt={altText || 'banner'}
            className="banner-img"
          />
        </picture>
      )}
    </div>
  );
};
