import { commonThemeGlobals, commonThemeMixins, commonThemeWidget, commonScrollbar } from './themeCommonStyles';

import merge from 'lodash/merge';
import {
  generateAlign,
  generateBreakPoints,
  generateCol,
  generateJustify,
  generateOffset,
} from '../../utils/generateFunctions';
import { COLORS_CONFIG, commonThemeVars } from './themeCommonVars';
import { Theme } from '@emotion/react';

// export type CustomConfigVars = (param: Pick<CommonTheme, CommonThemeVars>) => Partial<Record<CommonThemeVars, any>>;
// export type CustomMixinFunction = (param: Omit<Theme, 'mixins' | 'widgets'>) => Record<string, unknown>;
// export type CustomWidgetFunction = (param: Omit<Theme, 'widgets'>) => Record<string, unknown>;
// export type buildThemeFunction = (
//   customConfigVars: CustomConfigVars,
//   customMixin?: CustomMixinFunction,
//   customWidget?: CustomWidgetFunction,
// ) => Theme;

//TODO : Need to add typescript interface and types
export const buildTheme: any = (customConfigVars: any, customMixin: any, customWidget: any) => {
  const mergedConfigVars = customConfigVars
    ? merge({}, commonThemeVars, customConfigVars(commonThemeVars))
    : commonThemeVars;

  mergedConfigVars.breakpoints = generateBreakPoints(mergedConfigVars.breakpointWidths);

  const align = generateAlign(mergedConfigVars.breakpoints);
  const justify = generateJustify(mergedConfigVars.breakpoints);
  const offset = generateOffset(mergedConfigVars.breakpoints, mergedConfigVars.grid);
  const col = generateCol(mergedConfigVars.breakpoints, mergedConfigVars.grid);

  const partialThemeConfig = Object.assign({}, mergedConfigVars, {
    align,
    justify,
    offset,
    col,
  });

  const mergedMixin = customMixin
    ? merge({}, commonThemeMixins(partialThemeConfig), customMixin(partialThemeConfig))
    : commonThemeMixins(partialThemeConfig);

  const partialTheme = Object.assign({}, partialThemeConfig, {
    mixins: mergedMixin,
  });
  const mergedWidget = customWidget
    ? merge({}, commonThemeWidget(partialTheme), customWidget(partialTheme))
    : commonThemeWidget(partialTheme);

  const themeobj = Object.assign({}, partialTheme, { widgets: mergedWidget });
  return themeobj;
};

//TODO: themeBaseCommon is DEPRECATED (replaced by buildTheme). Remove once all references are refactored
export const themeBaseCommon = () => {
  const commonVars = buildTheme();
  return {
    icons: {},
    colors: {
      ...commonVars.colors,
    },
    breakpoints: {
      ...commonVars.breakpoints,
    },
    grid: {
      ...commonVars.grid,
    },
    align: {
      ...commonVars.align,
    },
    justify: {
      ...commonVars.justify,
    },
    offset: {
      ...commonVars.offset,
    },
    col: {
      ...commonVars.col,
    },
    mixins: {
      ...commonVars.mixins,
    },
  } as Pick<Theme, 'mixins' | 'colors' | 'breakpoints' | 'grid' | 'align' | 'justify' | 'offset' | 'col' | 'icons'>;
};

//TODO: commonColor is DEPRECATED (replaced by buildTheme). Remove once all references are refactored
export { COLORS_CONFIG as commonColors, commonThemeGlobals, commonScrollbar };
