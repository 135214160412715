import React from 'react';
import { css, useTheme } from '@emotion/react';
import { setLightness } from 'polished';

type AlertTypes = 'success' | 'warning' | 'error' | 'info';

export type AlertProps = {
  children: React.ReactNode;
  type: AlertTypes;
};

export const Alert = (props: AlertProps) => {
  const { children, type } = props;
  const theme = useTheme();
  const widgetTheme = theme.widgets.Alert || {};
  let color;
  switch (type) {
    case 'info':
      color = theme.colors.primary;
      break;
    case 'success':
      color = theme.colors.success;
      break;
    case 'warning':
      color = theme.colors.warning;
      break;
    default:
    case 'error':
      color = theme.colors.error;
      break;
  }
  const wrapper = css`
    padding: 20px;
    color: ${color};
    background-color: ${setLightness(0.95, color)};
    ${widgetTheme.wrapper}
  `;

  return (
    <div data-test="alert" css={wrapper}>
      <span>{children}</span>
    </div>
  );
};
