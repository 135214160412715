import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export function logApiRequest(
  outgoingRequest: AxiosRequestConfig = {},
  incomingResponse: AxiosResponse | undefined = {} as any,
  incomingRequestTime: Date | undefined,
  incomingResponseTime: Date | undefined,
  handlerName = '',
  message = '',
) {
  // TODO: use createLogger
  console.log({
    handler: handlerName,
    message,
    outgoingRequest: {
      method: outgoingRequest.method,
      url: outgoingRequest.url,
      //TODO: mask
      // headers: maskSensitiveHeaders(outgoingRequest.headers),
      headers: outgoingRequest.headers,
      //ISO 8601 date format
      time: incomingRequestTime && incomingRequestTime.toISOString(),
    },
    incomingResponse: {
      status: incomingResponse.status,
      // headers: maskSensitiveHeaders(incomingResponse.headers),
      headers: incomingResponse.headers,
      //ISO 8601 date format
      time: incomingResponseTime && incomingResponseTime.toISOString(),
    },
  });
}
