import { css, useTheme } from '@emotion/react';
import React, { memo, useEffect } from 'react';
import { NavigationEntryType, LinkEntryType } from 'tsconfig/types';
import { Grid, Icon, CmsLink } from 'common-ui';
import { useRecoilValue } from 'recoil';
import { selectUserDetails, allLoginType } from 'common-state';

export type NavItemProps = {
  fields: NavigationEntryType['fields'];
};

const NavItem = memo(({ fields }: NavItemProps) => {
  const { title, openAccordionByDefault, content } = fields;
  const [isActive, setIsActive] = React.useState<boolean>(false);

  useEffect(() => {
    if (openAccordionByDefault) {
      setIsActive(true);
    }
  }, []);

  const toggleTab = () => {
    setIsActive(prestate => !prestate);
  };
  const user = useRecoilValue(selectUserDetails);

  const currentLoginType = allLoginType.filter(loginType => loginType !== user.loginType);

  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Footer?.navItems}
  `;

  return (
    <div
      css={wrapper}
      data-test="navigation"
      className={`navItem-navigation ${isActive ? 'navItem-navigation-active' : ''}`}
    >
      <Grid
        align={'center'}
        justify={'between'}
        data-test="navigation-header"
        onClick={() => toggleTab()}
        onKeyDown={event => {
          if (event.keyCode === 32 || event.code === 'Space') {
            event.preventDefault();
            toggleTab();
          }
        }}
      >
        <p data-test="navigation-title">{title}</p>
        <div
          tabIndex={0}
          role="navigation"
          aria-label={title}
          className={`tab-indicator ${isActive ? 'tab-expand' : 'tab-collapse'}`}
        >
          <Icon kind="chevron-down" size="l" />
        </div>
      </Grid>
      <div className={`tab-panel ${isActive ? 'tab-expand' : 'tab-collapse'}`} data-test="navigation-content">
        <ul>
          {(content as LinkEntryType[])?.map((link, index: number) => {
            if (currentLoginType.some(value => (link.fields.targeting as unknown as string[])?.includes(value))) {
              return <></>;
            } else {
              return (
                <li key={index}>
                  <CmsLink contentItem={link} />
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
});

export default NavItem;
