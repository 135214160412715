import algoliasearch from 'algoliasearch/lite';
import { globals } from '../../utils/globals';

export const searchClientConfig = () => {
  const appId = `${globals.algoliaAppId}`;
  const searchKey = `${globals.algoliaSearchKey}`;

  return algoliasearch(appId, searchKey, {
    // Used for SSR Call only for Algolia
    headers: {
      Referer: `${globals.host}`,
    },
  });
};
export const algoliaClient = algoliasearch(`${globals.algoliaAppId}`, `${globals.algoliaSearchKey}`);
