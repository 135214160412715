import { useState } from 'react';
import { Button, Alert, Loading, handleDLProductClickEvent, handleAlgoliaAddToCartEvent, globals } from 'common-ui';
import { useActionAddToCart, useActionBatchAddToCart, useRecoilValue, selectCartDetails, cartAtom } from 'common-state';
import { AxiosErrorResponse } from 'tsconfig/types';

export type CartProps = {
  itemCode: string;
  qty: string;
  buttonText?: string;
  sendEvent?: any;
  hit?: any;
  isDisable?: boolean;
  method?: string;
  cartItems?: any;
  fontWeight?: string;
};

export const CartButton = (props: CartProps) => {
  const {
    itemCode,
    qty,
    buttonText = 'ADD TO BASKET',
    sendEvent,
    hit,
    isDisable,
    method,
    cartItems,
    fontWeight,
  } = props;
  const { itemsCount } = useRecoilValue(selectCartDetails);
  const isCartDataLoaded = useRecoilValue(cartAtom);

  const [apiCallStatus, setApiCallStatus] = useState('idle');
  const [apiCallMessage, setApiCallMessage] = useState('');

  const addItemToCart = useActionAddToCart();
  const batchAddItemToCart = useActionBatchAddToCart();

  const handleApiError = (error: AxiosErrorResponse) => {
    if (error?.response?.data?.errorResponse?.message) {
      setApiCallMessage(error.response.data?.errorResponse?.message); // Provide a default value of an empty string
    } else {
      setApiCallMessage(error.message || 'There is an error adding to cart. Please try again.'); // Provide a default value of an empty string
    }
  };

  const handleAddToCartClick = async (itemCode: string, qty: string) => {
    if (qty === '') {
      setApiCallStatus('error');
      setApiCallMessage("Minimum quantity of '1' required");
      setTimeout(() => {
        setApiCallStatus('');
      }, 3000);
      return;
    }

    setApiCallStatus('loading'); // Set loading status while the API call is in progress

    try {
      await addItemToCart(itemCode, qty);
      const queryID = localStorage.getItem('queryID');
      if (queryID) {
        handleAlgoliaAddToCartEvent(queryID, 'Algolia Added to Basket', itemCode);
      }

      // Adobe DataLayer
      const convertQtyToInt = parseInt(qty);
      if (itemsCount <= 0) {
        handleDLProductClickEvent('InitiateCart', hit, convertQtyToInt);
      } else {
        handleDLProductClickEvent('addToCart', hit, convertQtyToInt);
      }
      // Adobe DataLayer
      if (sendEvent) {
        sendEvent('conversion', hit, 'Algolia Added to Basket');
      }
      setApiCallStatus('success'); // Set success status after a successful API call
    } catch (error) {
      if (error) {
        handleApiError(error as AxiosErrorResponse);
      } else {
        // Handle the case where 'error' is not of type 'Error'
        setApiCallMessage('An error occurred.');
      }

      setApiCallStatus('error');
      setTimeout(() => {
        setApiCallStatus('');
      }, 5000);
    }
  };

  const handleBatchAddToCartClick = async (cartItems: any) => {
    setApiCallStatus('loading'); // Set loading status while the API call is in progress

    try {
      await batchAddItemToCart(cartItems);
      // Adobe DataLayer
      const convertQtyToInt = parseInt(qty);
      if (itemsCount <= 0) {
        handleDLProductClickEvent('InitiateCart', hit, convertQtyToInt);
      } else {
        handleDLProductClickEvent('addToCart', hit, convertQtyToInt);
      }
      if (sendEvent) {
        sendEvent('conversion', hit, 'Algolia Added to Basket');
      }
      setApiCallStatus('success'); // Set success status after a successful API call
    } catch (error) {
      if (error) {
        handleApiError(error as AxiosErrorResponse);
      } else {
        // Handle the case where 'error' is not of type 'Error'
        setApiCallMessage('An error occurred.');
      }
      setApiCallStatus('error');
      setTimeout(() => {
        setApiCallStatus('');
      }, 7500);
    }
  };

  return (
    <>
      <div className="cart-button">
        <Button
          style={{ fontWeight }}
          onClick={() => {
            if (method === 'batch') {
              handleBatchAddToCartClick(cartItems);
            } else {
              handleAddToCartClick(itemCode, qty);
            }
          }}
          disabled={apiCallStatus === 'loading' || isDisable || (!isCartDataLoaded && globals?.useNewCartAPI)}
        >
          {apiCallStatus === 'loading' || (!isCartDataLoaded && globals?.useNewCartAPI) ? (
            <>
              <span className="loadIcon">
                <Loading />
              </span>
              <span className="addingText">{!isCartDataLoaded && globals?.useNewCartAPI ? 'Loading' : 'Adding'}</span>
            </>
          ) : (
            <>
              <span>{buttonText}</span>
            </>
          )}
        </Button>
      </div>
      <div>{apiCallStatus === 'error' && <Alert type="error">{apiCallMessage}</Alert>}</div>
    </>
  );
};
