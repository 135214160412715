import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import { APICartResponse, VineCart, LineItem, ProductProps, cartApiErrorProps, CartResponse } from 'tsconfig/types.d';
import { NotificationType, notificationsAtom } from './notificationsAtom';
import { isBrowser } from '../helpers/utils';
import { globals, handleDLProductClickEvent } from 'common-ui';
import { getCookieValue } from 'common-ui/src/utils/getCookieValue';
import { authAtom, getAnonymousToken, refreshAuthToken, reloadAuthToken } from './authAtom';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { notificationMessage } from '../content/notificationMessage';
import { selectUserDetails } from './userAtom';

const commerceApiExtraConfig = (publicCsrfValue = '') => ({
  webApiExtraHeaders: {
    [globals.authAndWebApiCsrfHeaderName as string]: publicCsrfValue,
  },
});

/**
 * Atom for managing cart error state.
 *
 * This atom stores information about any errors that occur during cart operations.
 * - key: 'cartError' - unique identifier for the atom
 * - default: { isError: false, errorMessage: '' } - initial state with no errors
 */
export const cartError = atom<cartApiErrorProps>({
  key: 'cartError',
  default: {
    isError: false,
    errorMessage: '',
  },
});

//Atom
//----------------------------------------------------------------------
export const cartAtom = atom<VineCart>({
  key: 'cartAtom', // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectCartDetails = selector({
  key: 'selectCartDetails', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const cart: VineCart = get(cartAtom);
    if (typeof cart !== 'undefined' && typeof (cart as VineCart).totalLineItems !== 'undefined') {
      // ATG Cart
      let cartTotal = cart.totalPrice.amount;

      //TODO: Remove once backend fix the shipping for new cart API
      // New AWS cart - Subtract shipping cost from header mini cart
      if (globals.useNewCartAPI) {
        const shippingPrice = cart?.shippingInformation?.price?.amount || 0;
        cartTotal = Math.max(cart.totalPrice.amount - shippingPrice, 0);
      }

      return {
        itemsCount: cart.totalLineItems,
        cartTotal,
        hasQuantity: cart.totalLineItems > 0,
      };
    }
    return {
      itemsCount: 0,
      cartTotal: 0,
      hasQuantity: false,
    };
  },
});

/**
 * Cart data selector.
 *
 * This selector retrieves the cart data from the Recoil state.
 *
 * - key: 'cartData'
 * - get: Function - Fetches cart data from 'cartAtom' atom.
 */
export const selectCartData = selector({
  key: 'selectCartData',
  get: ({ get }) => {
    try {
      const cartData = get(cartAtom);
      return cartData;
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});

/**
 * Selector for retrieving cart error data.
 *
 * This selector retrieves the current state of cart error information from the 'cartError' atom.
 * - key: 'cartErrorData' - unique identifier for the selector
 * - get: Retrieves the current value of the 'cartError' atom
 *    - If successful, returns the cart error data
 *    - If an error occurs during retrieval, throws an error with the message 'Something went wrong'
 */
export const selectCartError = selector({
  key: 'selectCartError',
  get: ({ get }) => {
    try {
      const cartErrorData = get(cartError); // Retrieve current cart error data
      return cartErrorData; // Return retrieved cart error data
    } catch (error) {
      throw new Error('Something went wrong'); // Throw an error if something unexpected occurs during retrieval
    }
  },
});

//Hooks
//----------------------------------------------------------------------
export const useInitCartState = () => {
  const initCartStateWithAtgLegacyApi = useInitCartStateWithAtgLegacyApi();
  const initCartStateWithWebApi = useInitCartStateWithWebApi();

  return async (publicCsrfValue?: string) => {
    if (isBrowser()) {
      if (globals.useNewCartAPI) {
        return await initCartStateWithWebApi();
      } else {
        return await initCartStateWithAtgLegacyApi();
      }
    } else {
      console.log('useInitCartState skipping fetch as not in browser');
    }
  };
};

export const useInitCartStateWithAtgLegacyApi = () => {
  const [currentCartData] = useRecoilState(cartAtom);

  return async () => {
    if (currentCartData) {
      console.log('skipping init fetch of cart data as already defined');
      return;
    }
    await fetchCart();
  };
};

export const useInitCartStateWithWebApi = () => {
  const currentCartData = useRecoilValue(cartAtom);
  const commonCartError = useCommonCartError();

  return async () => {
    if (currentCartData) {
      console.log('skipping init fetch of cart data as already defined');
      return;
    }

    try {
      const { cartId } = getCurrentCartIdFromCookie();
      const authAtomState = getRecoil(authAtom);

      if (!authAtomState?.publicCsrf) {
        console.error('!token is not present');
      }

      // Check cartID Cookie is present
      if (cartId && cartId !== '') {
        try {
          // if public CSRF is not available in state, then fetch from /pagereload endpoint
          if (!authAtomState?.publicCsrf) {
            await reloadAuthToken();
          }
          // if cartID Cookie is present, then fetch cart using that cart ID
          await fetchCart();
        } catch (error: any) {
          // if error set common Error for the page.
          commonCartError(true, error?.response?.data?.message);
        }
      } else {
        try {
          // Fetch user login status from userAtom
          const { loginType } = getRecoil(selectUserDetails);

          if (loginType === 'unidentified') {
            // If user is Anonymous, get new token
            await getAnonymousToken();
          } else {
            // if public CSRF is not available in state, then fetch from /pagereload endpoint
            if (!authAtomState?.publicCsrf) {
              await reloadAuthToken();
            }
            // If user is loggedin, then call /refresh endpoint for tokens to get refreshed
            await refreshAuthToken();
          }

          // if cartID Cookie is not present, then create a new cart. It will generate the cart ID
          await createNewCart();
        } catch (error: any) {
          // if error set common Error for the page.
          commonCartError(true, error?.response?.data?.message);
        }
      }
    } catch (e) {
      // if error set common Error for the page.
      commonCartError(true, '');
      console.error('API ERROR', e);
    }
  };
};

export function useActionAddToCart() {
  const addToCartWithAtgLegacyApi = useAddToCartWithAtgLegacyApi();
  const addToCartWithWebApi = useAddToCartWithWebApi();

  return async (productSku: string, quantity: string) => {
    if (globals.useNewCartAPI) {
      return await addToCartWithWebApi(productSku, quantity);
    } else {
      return await addToCartWithAtgLegacyApi(productSku, quantity);
    }
  };
}

export function useAddToCartWithAtgLegacyApi() {
  const [, setCartData] = useRecoilState(cartAtom);
  const setNotification = useSetRecoilState(notificationsAtom);

  return async (productSku: string, quantity: string) => {
    try {
      const commerceApi = initCommerceApiWithGlobals();
      const cartData = await commerceApi.Cart.add(productSku, quantity);
      setCartData(cartData);

      const notificationTimeout = globals.enableNewMiniCart ? null : 5000;
      const notificationExpires = globals.enableNewMiniCart ? Infinity : new Date().getTime() + 5000;

      const notificationBase = {
        type: NotificationType.ADD_TO_CART,
        timeout: notificationTimeout,
        message: notificationMessage[NotificationType.ADD_TO_CART],
        expires: notificationExpires,
      };

      if (globals.enableNewMiniCart) {
        const notificationWithMiniCart = {
          ...notificationBase,
          miniCart: {
            itemCode: productSku,
            quantity: quantity,
          },
        };
        setNotification([notificationWithMiniCart]);
      } else {
        setNotification([notificationBase]);
      }
    } catch (e) {
      console.error('API ERROR', e);
      throw e;
    }
  };
}

export function useAddToCartWithWebApi() {
  const [, setCartData] = useRecoilState(cartAtom);
  const setNotification = useSetRecoilState(notificationsAtom);

  return async (productSku: string, quantity: string) => {
    async function doAddToCart() {
      const cartData = getRecoil(cartAtom);

      // If cartData is not available, throw Error
      if (!cartData) {
        throw new Error('Something went wrong. Please try again');
      }

      const authAtomState = getRecoil(authAtom);
      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const productData = {
        version: cartVersion,
        items: [
          {
            itemCode: productSku,
            quantity: parseInt(quantity),
          },
        ],
      };

      try {
        const cartData = await commerceApi.CartPreferences.addItemToCart({
          cartId,
          data: productData,
        });

        const productArray = await getProducts(cartData);
        if (productArray && productArray.length > 0) {
          setCartData({
            ...cartData,
            lineItems: productArray,
          });
        } else {
          setCartData(cartData);
        }

        const notificationTimeout = globals.enableNewMiniCart ? null : 5000;
        const notificationExpires = globals.enableNewMiniCart ? Infinity : new Date().getTime() + 5000;

        const notificationBase = {
          type: NotificationType.ADD_TO_CART,
          timeout: notificationTimeout,
          message: notificationMessage[NotificationType.ADD_TO_CART],
          expires: notificationExpires,
        };

        if (globals.enableNewMiniCart) {
          const notificationWithMiniCart = {
            ...notificationBase,
            miniCart: {
              itemCode: productSku,
              quantity: quantity,
            },
          };
          setNotification([notificationWithMiniCart]);
        } else {
          setNotification([notificationBase]);
        }
      } catch (error) {
        throw error; // Reject the promise with the error
      }
    }

    try {
      await doAddToCart();
    } catch (e: any) {
      return commonHandlerForWebApiRequestError(e, doAddToCart);
    }
  };
}

//TODO: add WebApi version of method
export function useActionBatchAddToCart() {
  const setCartData = useSetRecoilState(cartAtom);
  const [, setNotification] = useRecoilState(notificationsAtom);

  return async (cartItems: any) => {
    const commerceApi = initCommerceApiWithGlobals();
    const cartData = await commerceApi.Cart.batch(cartItems);
    if (cartData) {
      setCartData(cartData);
      setNotification([
        {
          type: NotificationType.ADD_TO_CART,
          timeout: 5000,
          message: notificationMessage[NotificationType.ADD_TO_CART],
          expires: new Date().getTime() + 5000,
        },
      ]);
    }
  };
}

//This is a hook called from ATG pages when they add to cart and we need to refresh header AND show a 'item added' notification
export function useCartGetAddedProduct() {
  const [, setNotification] = useRecoilState(notificationsAtom);

  const showProductAdded = async (event?: any) => {
    await fetchCart();
    const notificationTimeout = globals.enableNewMiniCart ? null : 5000;
    const notificationExpires = globals.enableNewMiniCart ? Infinity : new Date().getTime() + 5000;

    const notificationBase = {
      type: NotificationType.ADD_TO_CART,
      timeout: notificationTimeout,
      message: notificationMessage[NotificationType.ADD_TO_CART],
      expires: notificationExpires,
    };

    if (event?.detail?.itemcode && globals.enableNewMiniCart) {
      const notificationWithMiniCart = {
        ...notificationBase,
        miniCart: {
          itemCode: event?.detail?.itemcode,
          quantity: event?.detail?.quantity,
        },
      };
      setNotification([notificationWithMiniCart]);
    } else {
      setNotification([notificationBase]);
    }
  };

  const showProductRemoved = async () => {
    await fetchCart();
    setNotification([
      {
        type: NotificationType.REMOVED_FROM_CART,
        timeout: 5000,
        message: notificationMessage[NotificationType.REMOVED_FROM_CART],
        expires: new Date().getTime() + 5000,
      },
    ]);
  };
  return { showProductAdded, showProductRemoved };
}

// Creates new Cart (only neccesary in WebApi)
export async function createNewCart() {
  async function doCreateCart() {
    const authAtomState = getRecoil(authAtom);
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));

    const newCartCreationData = await commerceApi.CartPreferences.createNewCart();
    setRecoil(cartAtom, newCartCreationData);
  }

  try {
    await doCreateCart();
  } catch (e: any) {
    return commonHandlerForWebApiRequestError(e, doCreateCart);
  }
}

// Function to Fetch the latest cart data
export async function fetchCart() {
  if (globals.useNewCartAPI) {
    return await fetchCartWithWebApi();
  } else {
    return await fetchCartWithAtgLegacyApi();
  }
}

export async function fetchCartWithAtgLegacyApi() {
  try {
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig());
    const newCartData = await commerceApi.Cart.get();
    setRecoil(cartAtom, newCartData);
  } catch (e) {
    console.error('API ERROR', e);
  }
}

export async function fetchCartWithWebApi() {
  async function doFetchCart() {
    const authAtomState = getRecoil(authAtom);
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
    const { cartId } = getCurrentCartIdFromCookie();
    const newCartData = await commerceApi.CartPreferences.getMyCart({ cartId });

    // Pass the Cart data to getProducts fn to fetch product details
    const productArray = await getProducts(newCartData);

    // Set the product details in the same array
    if (productArray && productArray.length > 0) {
      setRecoil(cartAtom, {
        ...newCartData,
        lineItems: productArray,
      });
    } else {
      setRecoil(cartAtom, newCartData);
    }
  }

  try {
    await doFetchCart();
  } catch (e: any) {
    return commonHandlerForWebApiRequestError(e, doFetchCart);
  }
}

// Function to fetch Product details
export async function getProducts(newCartData: any) {
  const commerceApi = initCommerceApiWithGlobals();

  // fetch all products via product BFF
  const productItemCodes = (newCartData as CartResponse)?.lineItems?.map(item => {
    return item.sku.itemCode;
  });

  if (productItemCodes.length > 0) {
    const itemCodeQueryParam = productItemCodes.join(','); // Join itemCodes with a comma
    const products: ProductProps[] = await commerceApi.Product.getAllProductData(itemCodeQueryParam);
    const combinedArray: any = [];
    //TODO: this doesnt look correct
    newCartData?.lineItems?.forEach((cartdata: LineItem) => {
      const matchingItem = products.find(product => product.itemCode === cartdata.sku.productItemCode);
      const combinedItem = { ...cartdata, product: { ...matchingItem } };
      combinedArray.push(combinedItem);
    });
    return combinedArray;
  }

  return;
}

// Function to update the cart Quantity
export function useUpdateCartItemQuantity() {
  const setCartData = useSetRecoilState(cartAtom);

  return async (data: any, eventType = 'inputUpdate', prevCount: number) => {
    async function doUpdateCartItemQuantity() {
      const authAtomState = getRecoil(authAtom);
      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const newCartData = await commerceApi.CartPreferences.updateCartItem(
        { cartId },
        {
          version: cartVersion,
          ...data,
        },
      );

      const productArray = await getProducts(newCartData);

      const updatedQuantity = data?.items[0]?.quantity;

      // For Adobe Data Layer
      const updatedProduct = productArray.find((item: LineItem) => item?.lineItemId === data?.items[0]?.lineItemId);
      handleDLProductClickEvent(
        eventType === 'increment' || (eventType === 'inputUpdate' && updatedQuantity > prevCount)
          ? 'addToCart'
          : 'removeFromCart',
        updatedProduct?.product,
        updatedQuantity,
        'cart',
      );

      if (productArray && productArray.length > 0) {
        setCartData({
          ...newCartData,
          lineItems: productArray,
        });
      } else {
        setCartData(newCartData);
      }
    }

    try {
      await doUpdateCartItemQuantity();
    } catch (e: any) {
      //TODO: call recall when logged in
      return commonHandlerForWebApiRequestError(e, doUpdateCartItemQuantity);
    }
  };
}

// Function to Delete cart item
export function useDeleteCartItem() {
  const setCartData = useSetRecoilState(cartAtom);
  const currentCartData = useRecoilValue(cartAtom);

  return async (lineId: string, cartVersion: number) => {
    async function doDeleteCartItem() {
      const authAtomState = getRecoil(authAtom);
      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const newCartData = await commerceApi.CartPreferences.deleteItemFromCart({
        cartId,
        lineItemId: lineId,
        version: cartVersion,
      });

      const productArray = await getProducts(newCartData);

      // For Adobe Data Layer
      const removedItem = currentCartData?.lineItems?.find((item: LineItem) => item?.lineItemId === lineId);
      if (removedItem && removedItem.product) {
        handleDLProductClickEvent('removeFromCart', removedItem?.product, removedItem?.quantity || 1, 'cart');
      }

      if (productArray && productArray.length > 0) {
        setCartData({
          ...newCartData,
          lineItems: productArray,
        });
      } else {
        setCartData(newCartData);
      }
    }

    try {
      await doDeleteCartItem();
    } catch (e: any) {
      //TODO: call recall when logged in
      return commonHandlerForWebApiRequestError(e, doDeleteCartItem);
    }
  };
}

//Helper functions
//----------------------------------------------------------------------

// Function to show common cart page error
export function useCommonCartError() {
  const [, setCartApiError] = useRecoilState(cartError);

  const commonCartError = (isError: boolean, errorMessage = '') => {
    setCartApiError({
      isError,
      errorMessage,
    });
  };
  return commonCartError;
}

async function commonHandlerForWebApiRequestError(e: any, doAction?: any) {
  //Temp fix to refesh on 403 as well as 401 as API is currently returning 403 when it should be returing 401 on token expiration

  if (e.response?.status === 401 || e.response?.status === 403) {
    console.log('API call failed due to expired token. will update token and retry');
    const { loginType } = getRecoil(selectUserDetails);

    try {
      if (loginType === 'unidentified') {
        // If Anonymous user, then get new csrf token
        await getAnonymousToken();

        // create new cart if got new csrf token
        await createNewCart();
      } else {
        // If registered user, then call refresh api endpoint (it will replace the existing/expired cookies)
        await refreshAuthToken();

        if (doAction) {
          await doAction();
        }
      }
    } catch (e: any) {
      throw e;
    }
  } else {
    throw e;
  }
}

type CurrentCartCookieValues = {
  cartId: string;
  cartVersion: number;
};
export function getCurrentCartIdFromCookie(): CurrentCartCookieValues {
  const cookieValues = getCookieValue('cart-id')?.split('|') || [];
  return {
    cartId: cookieValues[0],
    cartVersion: parseInt(cookieValues[1], 10),
  };
}
