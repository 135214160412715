import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { WidgetEntryType, WidgetVariant } from 'tsconfig/types.d';
import { TrustPilotWidget } from '../../TrustPilotWidget/TrustPilotWidget';
import { getDomain } from '../../../../test/utils/getDomain';
import { RecentlyViewedProducts } from '../../RecentlyViewedProducts/RecentlyViewedProducts';

export type CmsWidgetProps = {
  contentItem: WidgetEntryType;
};

function getTagForVariant(variant: WidgetVariant) {
  switch (variant) {
    case 'separator':
      return 'hr';
    case 'blank':
    default:
      return 'div';
  }
}

export const CmsWidget = memo((props: CmsWidgetProps) => {
  const locale = process.env.NEXT_PUBLIC_LOCALE || 'en-US';
  const url = process.env.NEXT_PUBLIC_DW_URL || '';
  const businessUnitId = process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESSUNIT_ID;
  const dwDomain = getDomain(url);

  const { variant, tags } = props.contentItem.fields;

  const theme = useTheme();
  const wrapper = css`
    &.blank {
      margin-bottom: ${theme.space.lg};
      padding-bottom: ${theme.space.lg};
    }
  `;
  const CustomTag = getTagForVariant(variant) as keyof JSX.IntrinsicElements;
  const text = '';

  switch (variant) {
    case 'blank':
      return (
        <CustomTag data-test="widget-CmsText" css={wrapper} className={variant}>
          {text}
        </CustomTag>
      );
    case 'Customer Review Full Width Carousel':
      return <TrustPilotWidget locale={locale} businessUnitId={businessUnitId} dwDomain={dwDomain} tags={tags} />;
    case 'Recently Viewed':
      return <RecentlyViewedProducts />;
    default:
      return <CustomTag data-test="widget-CmsText" css={wrapper} />;
  }
});
