//TODO: move this somewhere else
export const isBrowser = () => typeof window === 'object';

export const formatDateToDDMMMYYFromISO = (dateString: string) => {
  const formattedDate = new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });

  return formattedDate;
};
