import React, { memo, useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { VideoEntryType } from 'tsconfig/types.d';
import { CmsImage } from '../CmsImage/CmsImage';
import { Play, PlayIcon } from '../../../svg';
import ReactPlayer from 'react-player/lazy';
import { isBrowser } from '../../../utils/isBrowser';
import { cmsVideoComponentSchema } from './schema';
import Grid from '../../grid/Grid';
import { fontCorrectionInRichText } from '../../../utils/fontCorrectionInRichText';

/* istanbul ignore else  */
export type CmsVideoComponentProps = {
  contentItem: VideoEntryType;
};

export const CmsVideoComponent = memo((props: CmsVideoComponentProps) => {
  const { varient, videoType, videoLink, image, videoText, buttonText, alignment } = props.contentItem.fields;
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (isBrowser()) {
      setHasWindow(true);
    }
  }, []);

  const [showThumbnail, setShowThumbnail] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const theme = useTheme();
  const videoComponentCss = css`
    ${theme.widgets?.CmsVideo?.videoCompCss}
    &.full-width {
      .full-width-overlay {
        background: linear-gradient(
          90deg,
          ${alignment === 'Left' ? 'rgba(0, 0, 0, 70%)' : 'rgba(255, 255, 255, 0%)'},
          rgba(195, 195, 195, 16.57%),
          ${alignment === 'Left' ? 'rgba(255, 255, 255, 0%)' : 'rgba(0, 0, 0, 70%)'}
        );
        .overlay-content {
          align-items: ${alignment === 'Left' ? 'flex-start' : 'flex-end'};
          text-align: ${alignment?.toLowerCase()};
        }
      }
    }
  `;
  const overlayCss = css`
    ${theme.widgets?.CmsVideo?.overlayCss}
  `;

  const handlePlayPause = () => {
    setShowThumbnail(!showThumbnail);
    setShowPlayButton(!showPlayButton);
  };
  const vimeoConfig = {
    vimeo: {
      playerOptions: {
        controls: true,
        playing: true,
        onEnded: { handlePlayPause },
      },
      iframeParams: {
        api: true,
        player_id: 'vimeo-player',
      },
    },
  };

  const videoContainer = () => {
    return hasWindow ? (
      <div data-testid={`${videoType}-player`} className={`video-container`}>
        {videoType === 'youtube' ? (
          <ReactPlayer
            light={showThumbnail && <CmsImage contentItem={image} />}
            controls
            playing={true}
            onEnded={handlePlayPause}
            url={videoLink}
            fallback={<CmsImage contentItem={image} />}
            config={{
              youtube: {
                playerVars: { showInfo: 0, fs: 1 },
              },
            }}
          />
        ) : videoType === 'vimeo' ? (
          <ReactPlayer light={showThumbnail && <CmsImage contentItem={image} />} config={vimeoConfig} url={videoLink} />
        ) : null}
      </div>
    ) : (
      ''
    );
  };

  // Full Width with fade overlay variant
  if (varient === 'Full Width with fade overlay') {
    return (
      <div
        data-testid="full-width-fade-overlay"
        css={videoComponentCss}
        className={`full-width ${!showPlayButton && !showThumbnail ? 'playing' : ''}`}
      >
        {videoContainer()}
        {showPlayButton && showThumbnail && (
          <div className="full-width-overlay">
            <Grid className="video-grid" justify="start">
              <Grid.Col sm={12} md={6} offset={{ md: alignment === 'Left' ? 0 : 6 }} className="video-grid-col">
                <div data-testid={`overlay-content-${alignment?.toLowerCase()}`} className="overlay-content">
                  <div className="video-text">
                    {videoText && /<[a-z][\s\S]*>/i.test(videoText) && (
                      <div dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(videoText, theme.fonts) }} />
                    )}
                  </div>
                  <div data-testid="play-button-with-text" className="play-button" onClick={handlePlayPause}>
                    <Play /> {buttonText}
                  </div>
                </div>
              </Grid.Col>
            </Grid>
          </div>
        )}
      </div>
    );
  }

  // Default: Basic Player variant
  return (
    <div data-testid="basic-player" css={videoComponentCss}>
      <script
        id="cms-video"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(cmsVideoComponentSchema({ image, videoLink })) }}
      ></script>
      {showPlayButton && showThumbnail && (
        <div data-testid="play-icon" onClick={handlePlayPause} css={overlayCss}>
          <PlayIcon />
        </div>
      )}
      {videoContainer()}
    </div>
  );
});
