// This page is temporary placeholder page for Algolia search

import React from 'react';
export const fiveStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const fourHalfStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 16 16" fill="none">
        <path
          d="M8.16667 1.5L10.2267 5.67333L14.8333 6.34667L11.5 9.59333L12.2867 14.18L8.16667 12.0133L4.04667 14.18L4.83333 9.59333L1.5 6.34667L6.10667 5.67333L8.16667 1.5Z"
          stroke="#D89B00"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16776 1.5L6.11897 5.65061L1.5 6.32574L4.84224 9.58108L4.05347 14.18L8.16776 12.0163V1.5Z"
          fill="#D89B00"
        />
        <path
          d="M6.11897 5.65061L6.17321 6.02167C6.29521 6.00384 6.40066 5.92716 6.45524 5.8166L6.11897 5.65061ZM8.16776 1.5H8.54276C8.54276 1.32572 8.42269 1.17442 8.25298 1.13481C8.08326 1.09521 7.90863 1.17774 7.83149 1.33402L8.16776 1.5ZM1.5 6.32574L1.44576 5.95469C1.30455 5.97533 1.1873 6.07433 1.14328 6.21008C1.09927 6.34583 1.13612 6.4948 1.23835 6.59438L1.5 6.32574ZM4.84224 9.58108L5.21184 9.64447C5.23271 9.52278 5.19234 9.3986 5.10389 9.31245L4.84224 9.58108ZM4.05347 14.18L3.68387 14.1166C3.65974 14.2573 3.71758 14.3995 3.83307 14.4834C3.94856 14.5673 4.10167 14.5783 4.22801 14.5119L4.05347 14.18ZM8.16776 12.0163L8.3423 12.3482C8.46557 12.2834 8.54276 12.1556 8.54276 12.0163H8.16776ZM6.45524 5.8166L8.50402 1.66598L7.83149 1.33402L5.78271 5.48463L6.45524 5.8166ZM1.55424 6.6968L6.17321 6.02167L6.06474 5.27955L1.44576 5.95469L1.55424 6.6968ZM5.10389 9.31245L1.76165 6.05711L1.23835 6.59438L4.58059 9.84972L5.10389 9.31245ZM4.42307 14.2434L5.21184 9.64447L4.47263 9.51769L3.68387 14.1166L4.42307 14.2434ZM7.99321 11.6844L3.87892 13.8481L4.22801 14.5119L8.3423 12.3482L7.99321 11.6844ZM8.54276 12.0163V1.5H7.79276V12.0163H8.54276Z"
          fill="#D89B00"
        />
      </svg>
    </span>
  );
};

export const fourStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const threeHalfStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 16 16" fill="none">
        <path
          d="M8.16667 1.5L10.2267 5.67333L14.8333 6.34667L11.5 9.59333L12.2867 14.18L8.16667 12.0133L4.04667 14.18L4.83333 9.59333L1.5 6.34667L6.10667 5.67333L8.16667 1.5Z"
          stroke="#D89B00"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16776 1.5L6.11897 5.65061L1.5 6.32574L4.84224 9.58108L4.05347 14.18L8.16776 12.0163V1.5Z"
          fill="#D89B00"
        />
        <path
          d="M6.11897 5.65061L6.17321 6.02167C6.29521 6.00384 6.40066 5.92716 6.45524 5.8166L6.11897 5.65061ZM8.16776 1.5H8.54276C8.54276 1.32572 8.42269 1.17442 8.25298 1.13481C8.08326 1.09521 7.90863 1.17774 7.83149 1.33402L8.16776 1.5ZM1.5 6.32574L1.44576 5.95469C1.30455 5.97533 1.1873 6.07433 1.14328 6.21008C1.09927 6.34583 1.13612 6.4948 1.23835 6.59438L1.5 6.32574ZM4.84224 9.58108L5.21184 9.64447C5.23271 9.52278 5.19234 9.3986 5.10389 9.31245L4.84224 9.58108ZM4.05347 14.18L3.68387 14.1166C3.65974 14.2573 3.71758 14.3995 3.83307 14.4834C3.94856 14.5673 4.10167 14.5783 4.22801 14.5119L4.05347 14.18ZM8.16776 12.0163L8.3423 12.3482C8.46557 12.2834 8.54276 12.1556 8.54276 12.0163H8.16776ZM6.45524 5.8166L8.50402 1.66598L7.83149 1.33402L5.78271 5.48463L6.45524 5.8166ZM1.55424 6.6968L6.17321 6.02167L6.06474 5.27955L1.44576 5.95469L1.55424 6.6968ZM5.10389 9.31245L1.76165 6.05711L1.23835 6.59438L4.58059 9.84972L5.10389 9.31245ZM4.42307 14.2434L5.21184 9.64447L4.47263 9.51769L3.68387 14.1166L4.42307 14.2434ZM7.99321 11.6844L3.87892 13.8481L4.22801 14.5119L8.3423 12.3482L7.99321 11.6844ZM8.54276 12.0163V1.5H7.79276V12.0163H8.54276Z"
          fill="#D89B00"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const threeStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const twoHalfStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 16 16" fill="none">
        <path
          d="M8.16667 1.5L10.2267 5.67333L14.8333 6.34667L11.5 9.59333L12.2867 14.18L8.16667 12.0133L4.04667 14.18L4.83333 9.59333L1.5 6.34667L6.10667 5.67333L8.16667 1.5Z"
          stroke="#D89B00"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16776 1.5L6.11897 5.65061L1.5 6.32574L4.84224 9.58108L4.05347 14.18L8.16776 12.0163V1.5Z"
          fill="#D89B00"
        />
        <path
          d="M6.11897 5.65061L6.17321 6.02167C6.29521 6.00384 6.40066 5.92716 6.45524 5.8166L6.11897 5.65061ZM8.16776 1.5H8.54276C8.54276 1.32572 8.42269 1.17442 8.25298 1.13481C8.08326 1.09521 7.90863 1.17774 7.83149 1.33402L8.16776 1.5ZM1.5 6.32574L1.44576 5.95469C1.30455 5.97533 1.1873 6.07433 1.14328 6.21008C1.09927 6.34583 1.13612 6.4948 1.23835 6.59438L1.5 6.32574ZM4.84224 9.58108L5.21184 9.64447C5.23271 9.52278 5.19234 9.3986 5.10389 9.31245L4.84224 9.58108ZM4.05347 14.18L3.68387 14.1166C3.65974 14.2573 3.71758 14.3995 3.83307 14.4834C3.94856 14.5673 4.10167 14.5783 4.22801 14.5119L4.05347 14.18ZM8.16776 12.0163L8.3423 12.3482C8.46557 12.2834 8.54276 12.1556 8.54276 12.0163H8.16776ZM6.45524 5.8166L8.50402 1.66598L7.83149 1.33402L5.78271 5.48463L6.45524 5.8166ZM1.55424 6.6968L6.17321 6.02167L6.06474 5.27955L1.44576 5.95469L1.55424 6.6968ZM5.10389 9.31245L1.76165 6.05711L1.23835 6.59438L4.58059 9.84972L5.10389 9.31245ZM4.42307 14.2434L5.21184 9.64447L4.47263 9.51769L3.68387 14.1166L4.42307 14.2434ZM7.99321 11.6844L3.87892 13.8481L4.22801 14.5119L8.3423 12.3482L7.99321 11.6844ZM8.54276 12.0163V1.5H7.79276V12.0163H8.54276Z"
          fill="#D89B00"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const twoStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const oneHalfStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 16 16" fill="none">
        <path
          d="M8.16667 1.5L10.2267 5.67333L14.8333 6.34667L11.5 9.59333L12.2867 14.18L8.16667 12.0133L4.04667 14.18L4.83333 9.59333L1.5 6.34667L6.10667 5.67333L8.16667 1.5Z"
          stroke="#D89B00"
          stroke-width="0.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16776 1.5L6.11897 5.65061L1.5 6.32574L4.84224 9.58108L4.05347 14.18L8.16776 12.0163V1.5Z"
          fill="#D89B00"
        />
        <path
          d="M6.11897 5.65061L6.17321 6.02167C6.29521 6.00384 6.40066 5.92716 6.45524 5.8166L6.11897 5.65061ZM8.16776 1.5H8.54276C8.54276 1.32572 8.42269 1.17442 8.25298 1.13481C8.08326 1.09521 7.90863 1.17774 7.83149 1.33402L8.16776 1.5ZM1.5 6.32574L1.44576 5.95469C1.30455 5.97533 1.1873 6.07433 1.14328 6.21008C1.09927 6.34583 1.13612 6.4948 1.23835 6.59438L1.5 6.32574ZM4.84224 9.58108L5.21184 9.64447C5.23271 9.52278 5.19234 9.3986 5.10389 9.31245L4.84224 9.58108ZM4.05347 14.18L3.68387 14.1166C3.65974 14.2573 3.71758 14.3995 3.83307 14.4834C3.94856 14.5673 4.10167 14.5783 4.22801 14.5119L4.05347 14.18ZM8.16776 12.0163L8.3423 12.3482C8.46557 12.2834 8.54276 12.1556 8.54276 12.0163H8.16776ZM6.45524 5.8166L8.50402 1.66598L7.83149 1.33402L5.78271 5.48463L6.45524 5.8166ZM1.55424 6.6968L6.17321 6.02167L6.06474 5.27955L1.44576 5.95469L1.55424 6.6968ZM5.10389 9.31245L1.76165 6.05711L1.23835 6.59438L4.58059 9.84972L5.10389 9.31245ZM4.42307 14.2434L5.21184 9.64447L4.47263 9.51769L3.68387 14.1166L4.42307 14.2434ZM7.99321 11.6844L3.87892 13.8481L4.22801 14.5119L8.3423 12.3482L7.99321 11.6844ZM8.54276 12.0163V1.5H7.79276V12.0163H8.54276Z"
          fill="#D89B00"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const oneStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          fill="#D89B00"
          stroke="#D89B00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const ZeroStar = () => {
  return (
    <span className="star-icons">
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg width="13" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99967 1.33252L10.0597 5.50585L14.6663 6.17919L11.333 9.42585L12.1197 14.0125L7.99967 11.8459L3.87967 14.0125L4.66634 9.42585L1.33301 6.17919L5.93967 5.50585L7.99967 1.33252Z"
          stroke="#969696"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export type RatingProps = {
  rating: number;
};

export const Rating = (props: RatingProps) => {
  const { rating } = props;

  if (!rating) return ZeroStar();

  if (rating % 1 === 0 || rating % 1 < 0.5) {
    const rounded = Math.round(rating);

    if (rounded === 5) {
      return fiveStar();
    }
    if (rounded === 4) {
      return fourStar();
    }
    if (rounded === 3) {
      return threeStar();
    }
    if (rounded === 2) {
      return twoStar();
    }
    if (rounded === 1) {
      return oneStar();
    }
  } else {
    const rounded = Math.round(rating);

    if (rounded === 5) {
      return fourHalfStar();
    }
    if (rounded === 4) {
      return threeHalfStar();
    }
    if (rounded === 3) {
      return twoHalfStar();
    }
    if (rounded === 2) {
      return oneHalfStar();
    }
  }

  return <div></div>;
};
